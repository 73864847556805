import React,{useState} from 'react'
import ResetP from '../assets/undraw_forgot_password_re_hxwm.svg'
import { auth } from '../services/api/auth'
import { useNavigate } from 'react-router-dom'


const ForgotPassword = () => {
 const [email, setEmail] = useState('')
 const navigate =  useNavigate()

 function handleChange(e){
  e.preventDefault()
  setEmail(e.target.value)
 }

 function handleSubmit(){
  console.log(email)
  const formData = new FormData()
  formData.append('email', email)
  auth.resetPassword(formData).then(res=>{if(res.status === 200){
    const token = res.data.token
    navigate(`/reset-password/${token}/${email}`)
  }}).catch(res=>console.log(res))
 }
 
  return (
    <section className='flex flex-col gap-4 sm:flex-row px-4 w-full items-center justify-center my-8'>
     <div className='w-full sm:w-[40%]'>
      <img src={ResetP} alt="" className='w-full'/>
     </div>
     <div className='w-full sm:w-[55%]'>
      <div className='mb-3'>
       <h1 className='text-3xl'>Forgot your password?</h1>
       <p>Enter your email below and we will send you a link to reset your password</p>
      </div>
      <form>
      <div className="addDiv">
      <label htmlFor="email">Email</label>
      <input
       type="text"
       name="email"
       id="email"
       placeholder="Enter your email here"
       value={email}
       onChange={handleChange}
      />
     </div>
     <button type='button' onClick={handleSubmit} className='submitBtn mt-3'>Continue</button>
      </form>
     </div>
    </section>
  )
}

export default ForgotPassword