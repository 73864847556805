import { useEffect, useState } from "react";
import cartImage from "../assets/wishlist.png";
import { useNavigate } from "react-router-dom";
import AuctionCard from "../components/Cards/AuctionCard";
import api from "../services/api";
import { CircularProgress } from "@mui/material";

const Watchlist = () => {
  const navigate = useNavigate();
  const [watchlist, setWatchlist] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    api.customer.watchlist
      .getWatchlist()
      .then((res) => {
        setWatchlist(res.data.watchlist);
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch((err) => {
        console.error(err);
        setLoading(false); // Set loading to false in case of error
      });
  }, []);

  if (loading) {
    // Show a loading spinner or message while the data is being fetched
    return (
      <main className="flex justify-center items-center h-screen">
        <div className="flex justify-center items-center">
            <CircularProgress />
          </div>
      </main>
    );
  }

  return (
    <main>
      {watchlist.length === 0 ? (
        <section className="flex flex-col items-center justify-center px-[30px] gap-4 mb-10">
          <img src={cartImage} alt="cart" className="h-3/4" />
          <div className="text-center flex flex-col gap-4">
            <h3 className="text-3xl font-extrabold">You have no watchlist</h3>
            <div className="flex flex-col gap-1 text-gray-600 text-sm md:text-base lg:text-xl font-medium">
              <p className="text-xl">
                Go on to auctions page and add to your watchlist
              </p>
            </div>
            <button
              type="button"
              onClick={() => navigate("/auctions")}
              className="bg-green-700 return"
            >
              Start Bidding
            </button>
          </div>
        </section>
      ) : (
        <section className="mx-5">
          <section className="my-4">
            <h3 className="text-[45px] text-gray-800 font-bold">
              Your Watchlist
            </h3>
            <p className="text-base">
              There are{" "}
              <span className="text-green-500">{watchlist.length}</span> auction items
              on your list
            </p>
          </section>
          <section className=" w-full lg:w-[85%]">
            {watchlist.map((x, y) => {
              return (
                <AuctionCard
                  productCurrentBid={x.auction?.auction_price}
                  productDetail={x.auction?.description}
                  thumbnail={
                    "https://api.ebn.ng/public/" +
                    x.auction?.thumbnail
                  }
                  productName={x.auction?.product_name}
                  id={x.id}
                  key={y}
                  productStatus={x.auction?.status}
                />
              );
            })}
          </section>
        </section>
      )}
    </main>
  );
};

export default Watchlist;
