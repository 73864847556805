import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchCart } from "../redux/Features/cartSlice";
import api from "../services/api";
import Loading from "../components/Loading";
import { formatPrice } from "../components/FormatPrice";
import { useSearchParams } from "react-router-dom";
import Receipt from "../components/Receipt";

const NewCheckout = () => {
  const dispatch = useDispatch();
  const { cartItems, status } = useSelector((store) => store.cart);
  const [step, setStep] = useState(1);
  const [orderId, setOrderId] = useState("");
  const [orderItems, setOrderItems] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [receiptInfo, setReceiptInfo] = useState({});

  const [query] = useSearchParams();
  const ref = query.get("reference");
  const trxref = query.get("trxref");
  const newOrderId = query.get("orderId");

  useEffect(() => {
    dispatch(fetchCart());
  }, [dispatch]);

  const [shippingInfo, setShippingInfo] = useState({
    shipping_option: {},
    address: {},
    email: {},
    phone: {},
  });

  useEffect(() => {
    if (orderId !== "") {
      setIsFetching(true);
      api.customer.checkout
        .fetchDeliveryInfo(orderId)
        .then((res) => setOrderItems(res.data))
        .catch(console.error)
        .finally(() => setIsFetching(false));
    }
  }, [orderId]);

  const handleNextStep = async () => {
    try {
      const hasAuctionItems = Object.values(cartItems).some(
        (item) => item.options?.type === "Auction"
      );

      // If step is 1 and there are auction items
      if (step === 1 && hasAuctionItems) {
        setLoading(true);
        await api.customer.bidding
          .bidsCheckout(shippingInfo)
          .then((res) => {
            setOrderId(res.data.order_id);
            setStep(2);
          })
          .catch(console.error)
          .finally(() => setLoading(false));
      }
      // If step is 1 and there are no auction items
      else if (step === 1 && !hasAuctionItems) {
        setLoading(true);
        await api.customer.checkout
          .checkout(shippingInfo)
          .then((res) => {
            setOrderId(res.data.order_id);
            setStep(2);
          })
          .catch(console.error)
          .finally(() => setLoading(false));
      }

      // If step is not 1, proceed normally
      if (step !== 1) {
        if (step < 3) setStep(step + 1);
      }
    } catch (error) {
      console.error("Error moving to the next step: ", error);
    }
  };

  const handlePreviousStep = async () => {
    try {
      if (step === 2) {
        setOrderItems({});
      }

      if (step > 1) setStep(step - 1);
    } catch (error) {
      console.error("Error moving to the previous step: ", error);
    }
  };

  const handleDeliveryChange = (productId, deliveryType) => {
    setShippingInfo((prevInfo) => ({
      ...prevInfo,
      shipping_option: {
        ...prevInfo.shipping_option,
        [productId]: deliveryType,
      },
    }));
  };

  const handleAddressChange = (productId, field, value) => {
    setShippingInfo((prevInfo) => ({
      ...prevInfo,
      [field]: {
        ...prevInfo[field],
        [productId]: value,
      },
    }));
  };

  const handlePayNow = () => {
    setIsPaying(true);
    api.customer.checkout
      .initializePayment(orderId)
      .then((res) => {
        const url = res.data.authorization_url;
        window.location.href = url;
      })
      .catch((err) => console.log(err))
      .finally(() => setIsPaying(false));
  };

  useEffect(() => {
    if (ref && trxref) {
      setIsConfirmingPayment(true);
      const formData = new FormData();
      formData.append("reference", ref);
      api.customer.checkout
        .confirmPayment(newOrderId, formData)
        .then((res) => {
          setReceiptInfo(res.data?.Transactions);
          setStep(4);
        })
        .catch((res) => {
          setStep(1);
        })
        .finally(() => setIsConfirmingPayment(false));
    }
  }, [newOrderId, ref, trxref]);

  if (isConfirmingPayment === true) {
    return <CircularProgress />;
  }

  const steps = ["Delivery Options", "Review Order", "Payment Options"];

  return (
    <div className="container mx-auto p-6">
      <div className="max-w-3xl mx-auto bg-white p-4 rounded-md">
        <h2 className="text-2xl text-center font-semibold mb-4">
          {newOrderId ? "Your Receipt" : "Checkout"}
        </h2>

        {!newOrderId && (
          <Stepper activeStep={step - 1} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}

        {step === 1 && (
          <div className="mt-6">
            <h3 className="text-xl mb-4">Step 1: Delivery Options</h3>
            {status === "loading" ? (
              <>
                {Array(3)
                  .fill()
                  .map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      height={60}
                      className="mb-4"
                    />
                  ))}
              </>
            ) : (
              cartItems.map((item) => (
                <div key={item.id} className="mb-4">
                  <div className="mb-2">
                    <span className="font-semibold">{item.name}</span> - ₦
                    {formatPrice(item.price)}
                  </div>
                  <select
                    className="border border-gray-300 p-2 rounded-md w-full"
                    value={shippingInfo.shipping_option?.[item.id] || ""}
                    onChange={(e) =>
                      handleDeliveryChange(item.id, e.target.value)
                    }
                  >
                    <option value="">Select Delivery Option</option>
                    <option value="pickup">Pick-Up</option>
                    <option value="delivery">Home Delivery</option>
                  </select>

                  {shippingInfo.shipping_option?.[item.id] === "delivery" && (
                    <div className="mt-4 flex flex-wrap gap-2">
                      <TextField
                        type="tel"
                        label="Phone number"
                        variant="outlined"
                        value={shippingInfo.phone?.[item.id] || ""}
                        onChange={(e) =>
                          handleAddressChange(item.id, "phone", e.target.value)
                        }
                      />
                      <TextField
                        type="email"
                        label="Email Address"
                        variant="outlined"
                        value={shippingInfo.email?.[item.id] || ""}
                        onChange={(e) =>
                          handleAddressChange(item.id, "email", e.target.value)
                        }
                      />
                      <TextField
                        label="Delivery Address"
                        variant="outlined"
                        value={shippingInfo.address?.[item.id] || ""}
                        onChange={(e) =>
                          handleAddressChange(
                            item.id,
                            "address",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  )}
                </div>
              ))
            )}

            <div className="flex justify-end mt-4">
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextStep}
                disabled={cartItems.some(
                  (item) =>
                    !shippingInfo.shipping_option?.[item.id] ||
                    (shippingInfo.shipping_option?.[item.id] === "delivery" &&
                      (!shippingInfo.address?.[item.id] ||
                        !shippingInfo.phone?.[item.id] ||
                        !shippingInfo.email?.[item.id]))
                )}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "white" }} />
                ) : (
                  "Next"
                )}
              </Button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="mt-6">
            {isFetching ? (
              <>
                <h3 className="text-xl mb-4">
                  Wait while we get a quote for delivery
                </h3>
                <Skeleton variant="rectangular" height={60} className="mb-4" />
              </>
            ) : (
              <>
                <h3 className="text-xl mb-4">Step 2: Review Your Order</h3>
                {orderItems?.Order_items?.map((item) => (
                  <div key={item.id} className="mb-4">
                    <div className="font-semibold">{item.name || item.product_name}</div>
                    <div>
                      Delivery:{" "}
                      {item.shipping_option === "pickup" ? (
                        "Pick-Up"
                      ) : (
                        <>
                          Home Delivery to{" "}
                          <span className="text-blue-500">{item.address}</span>
                        </>
                      )}
                    </div>
                    <div>
                      Price: ₦
                      {item.delivery_price
                        ? formatPrice(item.delivery_price)
                        : 0.0}
                    </div>
                  </div>
                ))}
                <div className="border-t border-gray-200 pt-4">
                  <div className="flex justify-between">
                    <span>Total Price:</span>
                    <span>
                      ₦
                      {orderItems?.total_product_amount
                        ? formatPrice(orderItems?.total_product_amount)
                        : 0.0}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>Delivery Price:</span>
                    <span>
                      ₦
                      {orderItems?.total_delivery_price
                        ? formatPrice(orderItems?.total_delivery_price)
                        : 0.0}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>Total Price + Delivery:</span>
                    <span>
                      ₦
                      {formatPrice(
                        orderItems?.total_delivery_price +
                          orderItems?.total_product_amount
                      ) || 0.0}
                    </span>
                  </div>
                </div>

                <div className="flex justify-between mt-4">
                  <Button variant="outlined" onClick={handlePreviousStep}>
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNextStep}
                    disabled={loading === true}
                  >
                    {loading ? <Loading className={"w-4"} /> : "Proceed to Pay"}
                  </Button>
                </div>
              </>
            )}
          </div>
        )}

        {step === 3 && (
          <div className="mt-6">
            <h3 className="text-xl mb-4">Step 3: Payment</h3>

            {isPaying ? (
              <CircularProgress />
            ) : (
              <div className="mt-4">
                <Button
                  onClick={handlePayNow}
                  variant="contained"
                  color="primary"
                >
                  Pay Now
                </Button>
              </div>
            )}

            <div className="flex justify-between mt-4">
              <Button variant="outlined" onClick={handlePreviousStep}>
                Back
              </Button>
            </div>
          </div>
        )}

        {step === 4 && (
          <Receipt
            transactionDetails={receiptInfo}
          />
        )}
      </div>
    </div>
  );
};

export default NewCheckout;
