import React from "react";
import styled from "styled-components";


const CategoryBox = ({  prodimage}) => {
 return (
  <Container>
    <img src={prodimage} alt={"just the title to be used"} />
  </Container>
 );
};
export default CategoryBox;

const Container = styled.div`
 display: flex;
 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 width: 370px;
 height: 220px;
 flex-shrink: 0;

 img{
  width: 100%;
  height: 100%;
 }

 @media (min-width: 1280px) {
  width: 500px;
  height: 250px;
 }
`;
