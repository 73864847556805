/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import ContactUs from "./Pages/ContactUs";
import Products from "./Pages/Products";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import LandingPage from "./Pages/LandingPage";
import Login from "./Pages/LoginPage";
import ErrorPage from "./Pages/ErrorPage";
import Cart from "./Pages/Cart";
import Wishlist from "./Pages/Wishlist";
import SignUp from "./Pages/SignUp";
import MyAccount from "./Pages/Account";
import { useSelector } from "react-redux";
import Vendors from "./Pages/Vendors";
import SingleVendor from "./Pages/SingleVendor";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Auctions from "./Pages/Auctions";
import TransactionHistory from "./Pages/TransactionHistory";
import AccountProfile from "./Pages/AccountProfile";
import BiddingHistory from "./Pages/BiddingHistory";
import RaiseClaim from "./Pages/RaiseClaim";
import SingleAuction from "./Pages/SingleAuction";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import ReportedIssues from "./Pages/ReportedIssues";
import ScrollToTop from "./components/ScrollToTop";
import ProductsByCategory from "./Pages/ProductsByCategory";
import Watchlist from "./Pages/Watchlist";
import NewCheckout from "./Pages/NewCheckout";
import ProductPage from "./Pages/Product";
import BidsWon from "./Pages/BidsWon";


const App = () => {
  const [userRole, setUserRole] = useState("unauth");
  const targetRef = useRef(null);

  const { user } = useSelector((store) => store.auth);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    user ? setUserRole(user.role) : setUserRole("unauth");
  }, [user]);

  useEffect(() => {
    targetRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const renderLayout = () => {
    if (userRole === "unauth") {
      return (
        <>
          <Navbar />
          <div>
            <Routes>
              <Route path="*" element={<ErrorPage />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<SignUp />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route exact path="/products" element={<Products />} />
              <Route exact path="/products/:search" element={<Products />} />
              <Route path='/products/categories/:categoryName/:categoryId' element={<ProductsByCategory />} />
              <Route exact path="/products/:slug/:productId" element={<ProductPage />} />
              <Route exact path="/vendors" element={<Vendors />} />
              <Route exact path="/vendor/:vendorName/:vendorId" element={<SingleVendor />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/wishlist" element={<Wishlist />} />
              <Route path="/auctions" element={<Auctions />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:token/:email" element={<ResetPassword />} />
            </Routes>
          </div>
          <Footer />
        </>
      );
    } else if (userRole === "user") {
      return (
        <>
          <Navbar />
          <div>
            <Routes>
              <Route path="*" element={<ErrorPage />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<SignUp />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route exact path="/products" element={<Products />} />
              <Route exact path="/products/:search" element={<Products />} />
              <Route
                path="/products/categories/:categoryName/:categoryId"
                element={<ProductsByCategory />}
              />
              <Route exact path="/vendors" element={<Vendors />} />
              <Route
                exact
                path="/vendor/:vendorName/:vendorId"
                element={<SingleVendor />}
              />
              <Route path="/cart" element={<Cart />} />
              <Route path="/wishlist" element={<Wishlist />} />
              <Route path="/watchlist" element={<Watchlist />} />
              <Route path="/auctions" element={<Auctions />} />
              <Route path="/bids-won" element={<BidsWon />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route element={user ? <Outlet /> : <Navigate to={"/login"} />}>
                <Route
                  path="/auctions/auction/:id"
                  element={<SingleAuction />}
                />
                <Route path="/myaccount/profile" element={<MyAccount />}>
                  <Route index element={<AccountProfile />} />
                  <Route
                    path="transaction-history"
                    element={<TransactionHistory />}
                  />
                  <Route path="bidding-history" element={<BiddingHistory />} />
                  <Route path="raise-claim" element={<RaiseClaim />} />
                  <Route path="issues" element={<ReportedIssues />} />
                </Route>
                <Route path="/checkout" element={<NewCheckout />} />
                <Route path="/products/:slug/:productId" element={<ProductPage />} />
              </Route>
            </Routes>
          </div>
          <Footer />
        </>
      );
    }
  };
  return (
    <div className="max-w-[1700px] mx-auto" ref={targetRef}>
      <ScrollToTop />
      {renderLayout()}
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default App;
