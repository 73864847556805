const CustomText = ({
 text,
 className,
 color,
 weight,
}) => {
 //text is the word
 return (
  <p
   className={className}
   style={{
    fontFamily:
     weight === 700
      ? "Roboto_Bold"
      : weight === 400
      ? "Roboto_Regular"
      : weight === 600
      ? "Roboto_Medium"
      : weight === 500
      ? "Roboto_Medium"
      : weight === 900
      ? "Roboto_Black"
      : "Roboto_Light",
    color,
   }}
  >
   {text}
  </p>
 );
};

export default CustomText;
