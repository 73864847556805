import React, { useState } from 'react'
import { auth } from '../services/api/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const ResetPassword = () => {
  const {token, email} = useParams()
  const navigate = useNavigate()
  console.log(token)
  const [passwordReset, setPasswordReset] = useState({
    password: "",
    password_confirmation: '',
    token: token,
    email: email
  })

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setPasswordReset((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const formData = new FormData()

    formData.append('email', passwordReset.email)
    formData.append('token', passwordReset.token)
    formData.append('password', passwordReset.password)
    formData.append('password_confirmation', passwordReset.password_confirmation)

    auth.activatePassword(formData).then(res => {
      if(res.status === 200){
        toast.success(res.data.message)
        navigate('/login')
      }
    }).catch(res => console.log(res))
  }

  return (
    <section className='max-w-[80%] xl:max-w-[60%] mx-auto my-8'>
      <h1 className='text-center text-xl mb-4'>Reset Password</h1>
      <form className='flex flex-col gap-3'>
        <div className="addDiv">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="Enter your email here"
            value={passwordReset.email}
            onChange={handleInputChange}
          />
        </div>
        <div className="addDiv">
          <label htmlFor="token">Token</label>
          <input
            type="text"
            name="token"
            id="token"
            placeholder="Enter your token here"
            value={passwordReset.token}
            onChange={handleInputChange}
          />
        </div>
        <div className="addDiv">
          <label htmlFor="password">password</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Enter your new password here"
            value={passwordReset.password}
            className='outline-none border-2 border-blue-500 px-3 py-2 w-full rounded-md'
            onChange={handleInputChange}
          />
        </div>
        <div className="addDiv">
          <label htmlFor="email">Confirm Password</label>
          <input
            type="password"
            name="password_confirmation"
            id="password_confirmation"
            placeholder="Re-enter your new password here"
            value={passwordReset.password_confirmation}
            className='outline-none border-2 border-blue-500 px-3 py-2 w-full rounded-md'
            onChange={handleInputChange}
          />
        </div>
        <button type='button' onClick={handleSubmit} className='submitBtn mt-3'>Reset</button>
      </form>
    </section>
  )
}

export default ResetPassword