// const baseUrl = "https://ebn.ng";
const baseUrl = "https://api.ebn.ng";

const routes = {
 register: (userType) => `${baseUrl}/${userType}/register`,
 login: (userType) => `${baseUrl}/${userType}/login`,
 'refresh-token':()=> `${baseUrl}/vendor/refresh`,
 "change-password":(userType)=>`${baseUrl}/${userType}/api/update/password`,
 'contact-us':()=> `${baseUrl}/user/contact-us`,
 customer: {
  url: () => `${baseUrl}/user`,
  "reset-password": () => `${routes.customer.url()}/resetPassword`,
  "activate-resetPassword": () =>
   `${routes.customer.url()}/activateResetPassword`,
   "get-vendors": ()=>`${routes.customer.url()}/get/all/vendors`,
  profile: {
   "get-details": () => `${routes.customer.url()}/api/show`,
   "update-profile": () => `${routes.customer.url()}/api/update/profile`,
   'get-transactions': () => `${routes.customer.url()}/api/get/transactions/report`
  },
  products:{
   'get-allProducts': ()=> `${routes.customer.url()}/get/all/products`,
   'get-allFilteredProducts': ()=> `${routes.customer.url()}/filter/products`,
   'get-Product': (id)=> `${routes.customer.url()}/get/single/product/${id}`,
   'get-allApprovedProducts': ()=> `${routes.customer.url()}/all/approved/products`,
   'product-by-category': (id)=> `${routes.customer.url()}/get/product/categories/${id}`
  },
  vendors:{
   'get-allVendors': ()=>`${routes.customer.url()}/get/all/vendors`,
   'get-Vendor': (id)=>`${routes.customer.url()}/get/vendor/${id}`,
   'get-Vendor-products':(id)=>`${routes.customer.url()}/vendor/product/${id}`,
   'get-allActiveVendors': ()=>`${routes.customer.url()}/get/active/vendors`,
   'get-allInactiveVendors': ()=>`${routes.customer.url()}/get/inactive/vendors`,
   'get-vendorProductCount': (id)=>`${routes.customer.url()}/vendor/product/${id}`,
  },
  brand:{
   "get-brands":()=>`${routes.customer.url()}/all/brands`,
   "get-brand":(id)=>`${routes.customer.url()}/get/brand/${id}`
  },
  category:{
   "get-categories":()=>`${routes.customer.url()}/all/categories`,
   "get-category":(id)=>`${routes.customer.url()}/get/category/${id}`
  },
  subcategory:{
   "get-subcategories":()=>`${routes.customer.url()}/all/subcategories`,
   "get-subcategory":(id)=>`${routes.customer.url()}/get/subcategory/${id}`
  },
  cart:{
   'add-to-cart':(id)=>`${routes.customer.url()}/add/to/cart/${id}`,
   'get-cart':()=>`${routes.customer.url()}/get/cart`,
   'get-count':()=>`${routes.customer.url()}/count/cart`,
   'delete-cart-item':(id)=>`${routes.customer.url()}/remove/cart/${id}`,
   'cart-increment' :(id)=>`${routes.customer.url()}/cart/increment/${id}`,
   'cart-decrement' :(id)=>`${routes.customer.url()}/cart/decrement/${id}`
  },
  wishlist:{
   'add-to-wishlist':(id)=>`${routes.customer.url()}/api/add/to/wishlist/${id}`,
   'get-wishlist': ()=>`${routes.customer.url()}/api/get/wishlist`,
   'delete-wishlist-item':(id)=>`${routes.customer.url()}/api/delete/wishlist/${id}`
  },
  watchlist:{
   'add-to-watchlist':(id)=>`${routes.customer.url()}/api/add/to/watchlist/${id}`,
   'get-watchlist': ()=>`${routes.customer.url()}/api/get/watchlist`,
   'delete-watchlist-item':(id)=>`${routes.customer.url()}/api/delete/watchlist/${id}`
  },
  claim:{
   'raise-claim': ()=>`${routes.customer.url()}/api/add/claim/report`,
   'view-claim': ()=>`${routes.customer.url()}/api/view/claim/report`
  },
  checkout:{
   'checkout':()=>`${routes.customer.url()}/api/cart/checkout`,
   'add-coupon': ()=>`${routes.customer.url()}/apply/coupon`,
   'get-coupon-calc' : ()=>`${routes.customer.url()}/api/calculate/coupon`,
   'remove-coupon' : ()=>`${routes.customer.url()}/api/remove/coupon`,
   'fetch-delivery-info': (id)=>`${routes.customer.url()}/api/fetch/delivery/price/${id}`,
   'initialize-payment': (id)=>`${routes.customer.url()}/api/initialize/payment/${id}`,
   'confirm-payment': (id)=>`${routes.customer.url()}/api/confirm/payment/${id}`,

  },
  bidding:{
   'make-bid': ()=>`${routes.customer.url()}/api/create/bid`,
   'get-all-auctions': ()=>`${routes.customer.url()}/get/all/auctions`,
   'get-all-biddings': ()=>`${routes.customer.url()}/api/get/all/biddings`,
   'get-auction': (id)=>`${routes.customer.url()}/api/get/auction/${id}`,
   'get-current-bid': (id)=>`${routes.customer.url()}/api/get/single/bidding/${id}`,
   'get-auction-winnings': ()=>`${routes.customer.url()}/api/get/auction/winnings`,
   'initialize-auction-payment': (id)=>`${routes.customer.url()}/api/auction/payment/initialize/${id}`,
   'confirm-auction-payment': (id)=>`${routes.customer.url()}/api/auction/confirm/payment/${id}`,
   'add-auction-to-cart': (id)=>`${routes.customer.url()}/add/auction/to/cart/${id}`,
   'bids-checkout': ()=>`${routes.customer.url()}/api/bid/cart/checkout`
  },
  review:{
    'make-a-review': ()=>`${routes.customer.url()}/api/review`,
    'get-review-for-product': (id)=>`${routes.customer.url()}/get/review/${id}`,
  }
 }
};

export default routes;
