import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 user: localStorage.getItem('customer') ? JSON.parse(localStorage.getItem('customer')) : null
}

export const authSlice = createSlice({
 name: "auth",
 initialState,
 reducers: {
  setUser: (state, action) => {
   state.user = action.payload;
  },
  logout: (state)=>{
   state.user = null;
   localStorage.removeItem('customer');
   localStorage.removeItem('customer-tokens');
  }
 },
});

export const { setUser, logout } = authSlice.actions;
export default authSlice.reducer;
