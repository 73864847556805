import React, { useState } from "react";
import styled from "styled-components";
import { addToCart,} from "../redux/Features/cartSlice";
import {
 addToWishlist,
 removeFromWishlist,
} from "../redux/Features/wishlistSlice";
import { useDispatch } from "react-redux";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { BsFillCartCheckFill } from "react-icons/bs";
import { MdOutlineShoppingCart } from "react-icons/md";
import ReactStars from "react-stars";
import { useNavigate } from "react-router-dom";
import { formatPrice } from "./FormatPrice";


const Offers = ({ image, product_name, price, rating, id, discount_price }) => {
 const dispatch = useDispatch();
 const [like, setLike] = useState(false);
 const [inCart, setInCart] = useState(false);

 const finalPrice = discount_price ? price - discount_price : price;
//  const discountPrice = (price * (discount_price / 100))

 const navigate = useNavigate();

 const handleWish = (e) => {
  e.stopPropagation();
  if (!like) {
   dispatch(
    addToWishlist({
     image,
     price: finalPrice,
     product_name,
     rating,
     id,
     discount_price,
     // tag,
    })
   );
  } else {
   dispatch(removeFromWishlist(id));
  }
  setLike(!like);
 };
 const handleAddToCartIcon = (e) => {
  e.stopPropagation();
  if (!inCart) {
   dispatch(
    addToCart({
     image,
     price: finalPrice,
     product_name,
     rating,
     id,
     discount_price,
     // tag,
    })
   );
  } else {
  //  dispatch(removeItem(id));
  }
  setInCart(!inCart);
 };
 return (
  <Container onClick={() => navigate(`/products/${id}`)}>
   <div className="left">
    <img src={image} alt="pics" />
   </div>

   <div className="right">
    <small className="w-[95%] truncate">{product_name}</small>
    <div className="rating">
     <ReactStars
      count={5}
      value={rating}
      size={25}
      color2="#ff8800"
      edit={false}
     />
    </div>
    <div className="flex items-center gap-4">
     <p className="text-[#3398ff]">₦{formatPrice(finalPrice)}</p>
     <p className="text-red-500">
      ₦<strike>{formatPrice(price)}</strike>
     </p>
    </div>
    <div className="flex items-center gap-2 mt-3 jus">
     <div className="productIcon" onClick={handleWish}>
      {!like ? (
       <AiOutlineHeart className="outline-black " />
      ) : (
       <AiFillHeart className="fill-red-700" />
      )}
     </div>
     <div className="productIcon" onClick={handleAddToCartIcon}>
      {!inCart ? (
       <MdOutlineShoppingCart />
      ) : (
       <BsFillCartCheckFill className="fill-valencia-500" />
      )}
     </div>
    </div>
   </div>
  </Container>
 );
};

export default Offers;

const Container = styled.div`
 display: flex;
 align-items: start;
 gap: 10px;
 padding-bottom: 20px;
 cursor: pointer;

 .left {
  height: 150px;
  width: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }

 .right {
  display: flex;
  flex-direction: column;

  small {
   font-size: 16px;
   font-weight: 500;
   width: 130px;
  }
  p {
   font-size: 15px;
   margin-top: -1px;
   font-weight: 600;
  }
 }
 @media (max-width: 1000px) {
  flex-direction: column;
 }
`;
