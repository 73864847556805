import React from "react";
import { formatPrice } from "./FormatPrice";

const Shipping = ({ totalPrice }) => {
 return (
  <section className="shipping">
   <div className="shippingItem pt-3">
    <h3>Total</h3>
    <span>₦{formatPrice(totalPrice)}</span>
   </div>
  </section>
 );
};

export default Shipping;
