export const colors = {
  white: '#ffffff',
  black: '#000000',
  brown: "#565050",
  blue:"#0063D1",
  secondaryBlue:"#3398FF",
  blueButtonColor : "#5480F1",
  gray3: 'rgba(130, 130, 130, 1)',
  gray4: 'rgba(189, 189, 189, 1)',
  orange: 'rgba(234, 91, 49, 1)',
  greenDeep: 'rgba(1, 16, 13, 1)',
  lightOrange: '#F8C8BA',
  lightOrange02: '#FBDED6',
  buttonGray:"rgba(123, 120, 120, 0.36)",
  green: '#24BA21',
  green2: '#04362D',
  green3: '#021B16',
  green4: '#27AE60',
  yellow: '#F3C31B',
  offWhite: '#F5F5F7',
  gray1: '#333333',
  gray2: '#4F4F4F',
  gray5: '#E0E0E0',
  gray6: '#F2F2F2',
  gray7: '#828282',
  red: '#E43535',
  lightGreen:"rgba(36, 186, 33, 0.24)"
};
