import React from 'react'
import Spinner from "../assets/VAyR.gif"

const Loading = ({className}) => {
 return (
  <div>
   <img src={Spinner} alt="spinner" className={className ? className :'w-[20px]'}/>
  </div>)
}

export default Loading
