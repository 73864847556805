import React, { useState } from "react";
import styled from "styled-components";
import banner from "../../assets/banner.jpg";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import {
  FreeMode,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
} from "swiper/modules";

const Showcase = () => {
  const [thumbsSwiper] = useState(null);

  return (
    <Container>
      <Swiper
        style={{
          "--swiper-navigation-color": "#ffffff",
          "--swiper-pagination-color": "#fff",
          marginBottom: "15px",
        }}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs, Pagination, Autoplay]}
        className="mySwiper2"
      >
        {[1, 2, 3].map((img, index) => {
          return (
            <SwiperSlide key={index} className="w-full ">
              <img
                src={banner}
                alt={index}
                className="w-full  object-contain"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Container>
  );
};

const Container = styled.div`
  margin: 30px;

  img {
    height: 100%;
    width: 100%;
  }
`;

export default Showcase;
