import React from "react";
import styled from "styled-components";
import FeaturedCard from "../Cards/FeaturedCard";
import { shuffleArray } from "../FormatPrice";
import { useEffect } from "react";
import api from "../../services/api";
import { useState } from "react";
import { Skeleton } from "@mui/material";

const TopFeaturedcategory = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchCategoriesWithProducts = async () => {
      try {
        const res = await api.customer.category.getCategories();
        const categories = res.data?.categories;

        const categoriesWithProducts = await Promise.all(
          categories.map(async (category) => {
            try {
              const categoryProducts =
                await api.customer.products.productsByCategory(category.id);
              return {
                ...category,
                productsCount: categoryProducts.data?.["products-counts"],
              };
            } catch (err) {
              console.error(
                `Error fetching products for category ${category.id}`,
                err
              );
              return { ...category, productsCount: 0 };
            }
          })
        );
        setCategories(categoriesWithProducts);
      } catch (err) {
        console.error("Error fetching categories", err);
      } finally {
        setLoading(false); // Set loading to false when done
      }
    };

    fetchCategoriesWithProducts();
  }, []);

  const shuffledCategories = shuffleArray(categories).slice(0, 5);

  return (
    <Container className="justify-start md:justify-center">
      {loading
        ? // Render Skeletons while loading
          Array.from(new Array(5)).map((_, idx) => (
            <Skeleton
              key={idx}
              variant="rect"
              width={210}
              height={118}
              style={{ margin: "10px" }}
            />
          ))
        : shuffledCategories.map((val, idx) => (
            <FeaturedCard
              featureImage={val.category_image}
              category={val.category_name}
              categoryId={val.id}
              featureStock={val.productsCount}
              key={idx}
            />
          ))}
    </Container>
  );
};

export default TopFeaturedcategory;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 0 5px;
  align-items: center;
  margin: 30px auto;
  overflow-x:scroll &::-webkit-scrollbar {
    display: none;
  }
`;
