import React from "react";
import styled from "styled-components";
import arrow from "../../assets/arrow-rights.svg";
import { useNavigate } from "react-router-dom";

const VendorShopCard = ({ image, shopName, shopInfo, stock, id }) => {
 const navigate = useNavigate();
 return (
  <Container className="w-[270px] lg:w-[270px]">
   <div className="top">
    <small>Shop</small>
    <img src={image} alt="" id="shop-image" />
   </div>

   <div className="bottom">
    <h5 className='w-[90%] truncate'>{shopName}</h5>
    <p className="info">{shopInfo || "Not Provided"}</p>
    <p className="stock">{stock} products</p>
    <button type="button" id="visit" onClick={() => navigate(`/vendor/${shopName}/${id}`)}>
     Visit Store <img src={arrow} alt="img" />
    </button>
   </div>
  </Container>
 );
};

export default VendorShopCard;

const Container = styled.div`
 position: relative;
 border: 1px solid #3398ff;
 height: 350px;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 gap: 5px;
 border-top-left-radius: 10px;
 border-bottom-right-radius: 10px;
 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 padding: 10px;
 cursor: pointer;

 .top {
  display: flex;
  flex-direction: column;
  height: 30%;

  small {
   position: absolute;
   top: 5px;
   right: 5px;
   background: #3398ff;
   padding: 7px;
   color: #fff;
  }
  img {
   width: 100%;
   object-fit:contain;
  }
 }

 .bottom {
  /* display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px; */
  margin-top: auto;
  // height: auto;
  h5 {
   font-weight: 500;
   font-size: 20px;
   /* color: #3398ff; */
  }
  .info {
   font-weight: 400;
   font-size: 16px;
   /* color: #3398ff; */
  }
  .stock {
   font-weight: 400;
   font-size: 15px;
   border: 1px solid #3398ff;
   padding: 5px 10px;
   text-align: center;
   border-radius: 20px;
   background: transparent;
   width: auto;
   margin-top: 10px;
   display: inline-block;
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  button {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   gap: 10px;
   padding: 10px;
   outline: none;
   border: none;
   background: #3398ff;
   border-radius: 5px;
   color: white;
   margin-top: 10px;
   &:hover {
    transform: scale(0.85);
    transition: all 0.8s linear;
    img {
     transform: translateX(10px);
     transition: all 0.6s ease-out;
    }
   }
  }

  /* .help {
   display: flex;
   align-items: center;
   font-size: 10px;
   gap: 7px;

   .num-info {
    display: flex;
    flex-direction: column;

    .num {
     color: #3398ff;
     cursor: pointer;
    }

    .center {
     color: rgba(123, 120, 120, 0.36);
     font-weight: 600;
     margin-top: -15px;
    }
   }
  } */
 }
`;
