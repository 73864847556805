import React from "react";
import styled from "styled-components";
import sub from "../../assets/subemail.JPG";

const Subscription = () => {
 return (
  <Container>
   <div className="mail">
    <h1>stay home & get your daily needs from our store</h1>

    {/* <div className="w-full relative bg-white rounded-md text-lg overflow-hidden">
     <input type="email" placeholder="Your Email Address" className="p-4 outline-none w-[90%]"/>
     <button type="button" className="absolute right-0 p-4 h-full rounded-md text-white text-sm bg-red-500 w-auto" disabled>Subscribe soon</button>
    </div> */}
   </div>

   <div className="image">
    <img src={sub} alt="" height="400px" />
   </div>
  </Container>
 );
};

export default Subscription;

const Container = styled.div`
 background: #f4b848;
 margin: 30px;
 padding: 30px;
 display: flex;

 .mail {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
  h1 {
   font-size: 52px;
   font-weight: bolder;
   text-transform: capitalize;
  }
  }
 }

 .image {
  width: 50%;
  img {
   width: 100%;
  }
 }
 @media (max-width: 928px) {
  .mail {
   h1 {
    font-size: 35px;
   }
  }
 }
 @media (max-width: 430px) {
  flex-direction:column;
  .mail {
   width: 100%;
   h1 {
    font-size: 20px;
   }
  }
  .image{
   width: 100%;
  }
 }
`;
