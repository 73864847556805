import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
 const navigate = useNavigate();
 return (
  <main className="h-full w-full flex flex-col justify-evenly items-center m-auto gap-3 py-24 px-5">
   <h1 className="text-[35px] lg:text-[60px] font-extrabold text-center">
    The Page You Tried Reaching Does Not Exist
   </h1>
   <p className="text-[20px]">Kinldy return to the home page</p>
   <button
    type="button"
    onClick={() => navigate("/")}
    className="bg-red-800 rounded-md px-5 py-2 text-white"
   >
    Back to Home page
   </button>
  </main>
 );
};

export default ErrorPage;
