import { useState } from "react";
import { Google } from "../assets";
import { colors } from "../utils/colors";
import eyeHide from "../assets/icons/eyeHide.svg";
import eyeShow from "../assets/icons/eyeShow.svg";
import email from "../assets/icons/email.svg";
import passwordCheck from "../assets/icons/passwordCheck.svg";
import edit from "../assets/icons/edit.svg";

const CustomTextInput = ({
 placeholder,
 value,
 setValue,
 type,
 label,
 className,
 width,
 height,
 fieldName,
 borderRadius,
 borderColor,
}) => {
 const [showPassword, setShowPassword] = useState(true);
 const [showCalendar, setShowCalendar] = useState(true);

 return (
  <div className={className}>
   <div className={`text-sm mb-[8px]`} style={{ color: colors.gray3 }}>
    {label}
   </div>
   <div
    className="py-2 px-2 text-sm flex flex-row items-center"
    style={{
     color: colors.gray4,
     borderWidth: 1,
     borderColor: borderColor,
     borderRadius: borderRadius,
     height: height,
     width: width,
    }}
   >
    <div>
     {fieldName === "name" ? (
      <div>
       <img src={edit} alt={"edit"} />
      </div>
     ) : fieldName === "email" ? (
      <div>
       <img src={email} alt={"email"} />
      </div>
     ) : fieldName === "password" ? (
      <div>
       <img src={passwordCheck} alt="passwordCheck" />
      </div>
     ) : (
      ""
     )}
    </div>

    <input
     value={value}
     placeholder={placeholder && placeholder}
     type={showPassword ? type : "text"}
     name={placeholder}
     onChange={(e) => setValue(e.target.value)}
     className={`${className} text-black`}
     style={{
      outline: "none",
     }}
    />
    {type === "password" ? (
     <div
      onClick={() => setShowPassword(!showPassword)}
      style={{ paddingLeft: 17, cursor: "pointer",marginLeft:'auto' }}
     >
      {showPassword ? (
       <img src={eyeShow} alt="hide" />
      ) : (
       <img src={eyeHide} alt="show" />
      )}
     </div>
    ) : (
     type === "calendar" && (
      <div
       onClick={() => setShowCalendar(!showCalendar)}
       style={{ paddingLeft: 17, cursor: "pointer" }}
      >
       {showCalendar ? <Google /> : ""}
      </div>
     )
    )}
   </div>
  </div>
 );
};

export default CustomTextInput;
