import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Rating,
} from "@mui/material";
import CommentPicture from "../assets/comments.svg";
import Comment from "./Comment";
import api from "../services/api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CommentsSection = ({ comments, loading, id, vendor_id, setLoading }) => {
  const { user } = useSelector((store) => store.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [rating, setRating] = useState(0);

  const navigate = useNavigate();

  // Show modal
  const handleOpen = () => {
    if (!user) {
      navigate("/login");
    } else {
      setIsModalOpen(true);
    }
  };

  // Close modal
  const handleClose = () => {
    setIsModalOpen(false);
    setCommentText(""); // Clear the comment text when closing
    setRating(0); // Reset the rating
  };

  // Handle comment submission
  const handleSubmit = () => {
    if (commentText.trim() && rating > 0) {
      const formData = new FormData();
      formData.append("product_id", id);
      formData.append("comment", commentText);
      formData.append("quality", rating);
      formData.append("vendor_id", vendor_id);

      
      api.customer.review
      .postReview(formData)
      .then((res) => {
          setLoading(true);
          toast.info("Review submitted");
          handleClose();
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    } else {
      alert("Please provide both a comment and a rating.");
    }
  };

  // Get the latest 5 comments
  const latestComments = comments.slice(0, 5);

  return (
    <div className="mt-8">
      {/* Button to view more comments */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Verified Customer Feedback</h2>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          {!user ? "Login to review" : " Add Review"}
        </Button>
      </div>

      {loading ? (
        <>
          <div className="bg-gray-200 h-6 w-2/3 mb-4 rounded"></div>
          <div className="bg-gray-200 h-6 w-4/5 mb-4 rounded"></div>
          <div className="bg-gray-200 h-6 w-3/4 rounded"></div>
        </>
      ) : (
        <>
          {/* Display Comments */}
          <div className="space-y-4">
            {latestComments.length > 0 ? (
              latestComments.map((comment, index) => (
                <Comment
                  key={index}
                  name={comment?.user.name}
                  date={comment.created_at}
                  content={comment.comment}
                  rating={comment.rating}
                  avatar={"https://api.ebn.ng/public/upload/user_profile_pics/" + comment?.user.photo}
                />
              ))
            ) : (
              <div className="flex flex-col items-center justify-center w-full mx-auto text-center mt-4">
                <img
                  src={CommentPicture}
                  alt="No reviews"
                  className="w-24 h-24 mb-4"
                />
                <p className="text-gray-500 text-lg">
                  No reviews yet. Be the first to review!
                </p>
              </div>
            )}
          </div>
        </>
      )}

      {/* Modal for adding a comment */}
      <Dialog open={isModalOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Add a Review</DialogTitle>
        <DialogContent>
          <div className="space-y-4">
            {/* Comment Text Input */}
            <TextField
              label="Your Comment"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
            />

            {/* Rating Component */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Rating
              </label>
              <Rating
                value={rating}
                onChange={(_, newRating) => setRating(newRating)}
                precision={0.5}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CommentsSection;
