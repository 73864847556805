import React from "react";
import {formatPrice} from '../FormatPrice'

const BidderCard = ({ bidder, bid }) => {
 return (
  <article className="h-[70px] w-[150px] flex flex-col gap-3 items-center justify-center py-2 shadow-xl border-2 border-green-300 rounded-lg">
   <h3>User: {bidder}</h3>
   <p>
    <strong>Bid:</strong> ₦{formatPrice(bid)}
   </p>
  </article>
 );
};

export default BidderCard;
