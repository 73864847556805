import cartImage from "../assets/wishlist.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NewProductCard from "../components/Cards/NewProductCard";

const Wishlist = () => {
 const navigate = useNavigate();
 const { wishes, wishListItems } = useSelector((store) => store.wishlist);


 return (
  <main>
   {wishListItems.length === 0 ? (
    <section className="flex flex-col items-center justify-center px-[30px] gap-4 mb-10">
     <img src={cartImage} alt="cart" className="h-3/4" />
     <div className="text-center flex flex-col gap-4">
      <h3 className="text-3xl font-extrabold">
       Your Wishlist Is Currently Empty!
      </h3>
      <div className="flex flex-col gap-1 text-gray-600 text-sm md:text-base lg:text-xl font-medium">
       <p className="text-xl">Seems like you don't have wishes here.</p>
       <p className="text-base">Make a wish!</p>
      </div>
      <button
       type="button"
       onClick={() => navigate("/")}
       className="bg-green-700 return"
      >
       Start Shopping
      </button>
     </div>
    </section>
   ) : (
    <section className="mx-5">
     <section className="my-4">
      <h3 className="text-[45px] text-gray-800 font-bold">Your Wishes</h3>
      <p className="text-base">
       There are <span className="text-green-500">{wishes}</span> wishes on your
       list
      </p>
     </section>
     <section className="wishes">
      {wishListItems.map((wish) => {
       return (
        <NewProductCard
         key={wish.id}
         price={wish.price}
         product_name={wish.product_name}
         rating={wish.rating}
         image={wish.image}
         id={wish.id}
         tag={wish.tag}
         discount_price={wish.discount_price}
        />
       );
      })}
     </section>
    </section>
   )}
  </main>
 );
};

export default Wishlist;
