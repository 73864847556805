import React, { useEffect, useState } from "react";
import styled from "styled-components";
import demo from "../../assets/placeholder.jpg";
import { Link } from "react-router-dom";
import VendorShopCard from "../Cards/VendorShopCard";
import api from "../../services/api";

const Vendor = () => {
 const [vendors, setVendors] = useState([]);

 useEffect(() => {
  const fetchVendorsWithProductCounts = async () => {
    try {
      // Fetch the list of vendors
      const res = await api.customer.getVendors();
      const vendors = res.data?.vendors.filter((val) => val.status === "active" && val.vendor_short_info !== "");
      
      // Fetch product count for each vendor using their ID
      const vendorsWithProductCounts = await Promise.all(
        vendors.map(async (vendor) => {
          try {
            const vendorData = await api.customer.vendor.vendorProductCount(vendor.id); 
            return {
              ...vendor,
              productCount: vendorData.data?.['product-count']
              // Adjust based on actual response structure
            };
          } catch (err) {
            console.error(`Error fetching product count for vendor ${vendor.id}`, err);
            return { ...vendor, productCount: 0 }; // Default to 0 or handle the error appropriately
          }
        })
      );

      // Update state with vendors and their product counts
      setVendors(vendorsWithProductCounts);
    } catch (err) {
      console.error('Error fetching vendors', err);
    }
  };

  fetchVendorsWithProductCounts();
}, []);

 return (
  <Container>
   <header>
    <h2>Our Vendor stores</h2>
    <Link className="all" to={"/vendors"}>
     <h2 className="left">All vendors</h2>
    </Link>
   </header>

   <main>
    {/* would change that "" to null later */}
    {vendors
     .slice(0, 4)
     .map((val, idx) => {
      return (
       <VendorShopCard
        image={demo}
        // image={val.photo ? "https://ebn.ng/public/upload/" + val.photo : demo}
        shopName={val.username}
        shopInfo={val.vendor_short_info}
        stock={val.productCount}
        key={val.id}
        id={val.id}
       />
      );
     })}
   </main>
   <Link className="block md:hidden ml-auto py-2 underline" to={"/vendors"}>
     <h2 className="left">More vendors {">>"}</h2>
    </Link>
  </Container>
 );
};

export default Vendor;

const Container = styled.div`
 margin: 30px;

 header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;

  h2 {
   font-weight: 500;
   font-size: 20px;
   color: black;
  }

  .all {
   color: rgba(123, 120, 120, 0.36);
  }
 }

 section {
 }

 main {
  margin-top: 20px;
  display:flex;
  flex-wrap: wrap;
  gap:20px;
  justify-content: center;
 }

 @media (max-width: 1024px) {
  main {
   display:flex;
  flex-wrap: wrap;
  gap:20px;
  }
 }
 @media (max-width: 430px) {
  header{
    display:flex;
    flex-direction:column;
    
    .left{
      display: none;
    }
  }
 }
`;
