const service = {
  reloadPage: () => window.location.reload(),
  removeTokensLocalStorage: () => localStorage.removeItem("customer-tokens"),
  setTokensLocalStorage: (data) =>
    localStorage.setItem("customer-tokens", JSON.stringify(data)),
  setUserLocalStorage: (data) =>
    localStorage.setItem("customer", JSON.stringify(data)),
  truncateText: (text, limit = 16) =>
    `${text?.length > limit ? text.slice(0, limit) + "..." : text}`,
  getTokensLocalStorage: () => JSON.parse(localStorage.getItem("customer-tokens")),
};
export default service;
