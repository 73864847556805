import React, { useState, useEffect } from "react";
import AuctionCard from "../components/Cards/AuctionCard";
import ReactPaginate from "react-paginate";
import api from "../services/api";
import { Skeleton } from "@mui/material";

const Auctions = () => {
  const [auctions, setAuctions] = useState([]);
  const [bidIs, setBidIs] = useState("open");
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    api.customer.bidding
      .getAllAuctions()
      .then((res) => {
        setAuctions(res.data?.auctions);
      })
      .catch((res) => console.log(res))
      .finally(() => setIsFetching(false));
  }, []);

  // pagination
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const filteredAuctions = auctions.filter(
    (auction) =>
      (bidIs === "open" && auction.status === "open") ||
      (bidIs === "close" && auction.status === "close")
  );

  const pageCount = Math.ceil(filteredAuctions?.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredAuctions?.slice(startIndex, endIndex);

  return (
    <main className="auctionPage">
      <section
        id="auctionPage_auctions"
        className="flex flex-col justify-center items-center w-full h-full"
      >
        <div className="flex gap-4 justify-center my-6 w-full ">
          <button
            type="button"
            onClick={() => setBidIs("open")}
            className={bidIs === "open" ? "currentVendor" : "vendorBtn"}
          >
            Active Bids
          </button>
          <button
            type="button"
            onClick={() => setBidIs("close")}
            className={bidIs === "close" ? "currentVendor" : "vendorBtn"}
          >
            Ended Bids
          </button>
        </div>
        <div className="w-[95%] mx-auto">
          {isFetching
            ? [...Array(itemsPerPage)].map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rect"
                  height={118}
                  style={{ marginBottom: "16px" }}
                />
              ))
            : paginatedData?.map((x) => (
                <AuctionCard
                  productCurrentBid={x.auction_price}
                  productDetail={x.description}
                  thumbnail={
                    "https://api.ebn.ng/public/" +
                    x.thumbnail
                  }
                  productName={x.product_name}
                  id={x.id}
                  key={x.id} // Use a unique key
                  productStatus={x.status}
                />
              ))}
          {bidIs === "open" && paginatedData?.length === 0 && (
            <div>NO ONGOING BIDS</div>
          )}
          {bidIs === "close" && paginatedData?.length === 0 && (
            <div>NO ENDED BIDS</div>
          )}
        </div>
        <div className="my-8">
          <ReactPaginate
            previousLabel={"⇽"}
            nextLabel={"⇾"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="paginateContainer"
            pageClassName="cursor-pointer py-1 px-2 rounded-full bg-gray-200 font-bold hover:bg-green-500 hover:text-white"
            previousClassName="nextPrev"
            nextClassName="nextPrev"
            activeClassName="bg-green-500 text-white"
          />
        </div>
      </section>
    </main>
  );
};

export default Auctions;
