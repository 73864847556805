import React, { useEffect, useState } from "react";
import api from "../services/api";
import { formatPrice } from "../components/FormatPrice";
import { CircularProgress } from "@mui/material";
import ReactPaginate from "react-paginate";

const BiddingHistory = () => {
  const [bidsHistory, setBidHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0); // Track the current page
  const itemsPerPage = 10; // Number of items per page

  useEffect(() => {
    api.customer.bidding
      .getAllBiddings()
      .then((res) => {
        setBidHistory(res.data?.bidding);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  // Handle page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Calculate the slice for the current page
  const indexOfLastBid = (currentPage + 1) * itemsPerPage;
  const indexOfFirstBid = indexOfLastBid - itemsPerPage;
  const currentBids = bidsHistory.slice(indexOfFirstBid, indexOfLastBid);

  // Calculate the total number of pages
  const pageCount = Math.ceil(bidsHistory.length / itemsPerPage);

  return (
    <section>
      <div>
        <h1 className="text-2xl">Bidding History</h1>
      </div>
      <div className="px-3 overflow-x-auto my-4">
        {loading ? (
          <div className="flex justify-center items-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr className="tableHeadRow">
                  <th className="tableHead">Auction id</th>
                  <th className="tableHead">Bid Price</th>
                  <th className="tableHead">Date</th>
                  <th className="tableHead">Time</th>
                </tr>
              </thead>
              <tbody>
                {currentBids.map((bids) => {
                  const dateTime = new Date(bids.created_at);
                  const date = dateTime.toISOString().split("T")[0];
                  const time = dateTime
                    .toISOString()
                    .split("T")[1]
                    .split(".")[0];
                  return (
                    <tr key={bids.id} className="tableRow">
                      <td>{bids.auction_id}</td>
                      <td>₦{formatPrice(bids.bidding_price)}</td>
                      <td>{date}</td>
                      <td>{time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="mt-4">
              <ReactPaginate
                previousLabel={"⇽"}
                nextLabel={"⇾"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="paginateContainer"
                pageClassName="cursor-pointer py-1 px-2 rounded-full bg-gray-200 font-bold hover:bg-green-500 hover:text-white"
                previousClassName="nextPrev"
                nextClassName="nextPrev"
                activeClassName="bg-green-500 text-white"
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default BiddingHistory;
