import React from "react";
import LoginDetails from "../components/SignUp/LoginDetails";

const Login = () => {
  return (
    <main className="py-12 flex flex-col lg:flex-row gap-4 justify-around items-center mx-auto">
      <section className="w-full md:w-[80%] lg:w-[50%]">
        <LoginDetails />
      </section>
    </main>
  );
};

export default Login;
