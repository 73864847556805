import CustomButton from "../../components/CustomButton"; 
import { colors } from "../../utils/colors";

const VendorDetails = () => {
  // Redirect functions for Login and Register to open in a new tab
  const redirectToLogin = () => {
    window.open("https://dashboard-ebn.netlify.app/", "_blank"); 
  };

  const redirectToRegister = () => {
    window.open("https://dashboard-ebn.netlify.app/vendor/register", "_blank"); 
  };

  return (
    <div className="flex justify-center items-center space-x-4 my-4">
      {/* Button to go to Login page */}
      <CustomButton
        text="Login"
        onClick={redirectToLogin}
        paddingTop={16}
        paddingBottom={16}
        paddingLeft={36}
        paddingRight={36}
        textWeight={400}
        background={colors.green}
        color={colors.white}
        borderRadius={4}
      />

      {/* Button to go to Register page */}
      <CustomButton
        text="Register"
        onClick={redirectToRegister}
        paddingTop={16}
        paddingBottom={16}
        paddingLeft={36}
        paddingRight={36}
        textWeight={400}
        background={colors.blue} 
        color={colors.white}
        borderRadius={4}
      />
    </div>
  );
};


export default VendorDetails;
