import styled from "styled-components";
import NewProductCard from "../Cards/NewProductCard";
import bannerimage from "../../assets/smallbanner.png";

const FeaturedProducts = ({ onView, products }) => {
  const featuredProducts = products.filter((product) => product.featured === 1);

  return (
    <Container>
      <header>
        <div className="border-2 border-[#009900] w-full">
          <article className="parallelogram bg-[#009900]">
            <h1 className="text-white font-semibold">Featured Products</h1>
          </article>
        </div>
      </header>

      <main className="flex flex-col xl:flex-row gap-4 items-center">
        <div className="banner-container w-full xl:w-[30%] h-auto">
          <img
            src={bannerimage}
            alt="Advertisement banner"
            className="w-full h-auto object-cover"
          />
        </div>
        <div className="top w-full lg:w-[80%]">
          {featuredProducts.length > 0 ? (
            featuredProducts
              .slice(0, 5)
              .map((product, idx) => (
                <NewProductCard
                  onView={onView}
                  key={idx}
                  price={product.selling_price}
                  product_name={product.product_name}
                  rating={product.productRating}
                  image={
                    "https://api.ebn.ng/public" + product.product_thumbnail
                  }
                  id={product.id}
                  tag={product.productTag}
                  discount={product.discount_price}
                  createdBy={product.created_by}
                  slug={product.product_slug}
                  {...product}
                />
              ))
          ) : (
            <p>No Featured Products Available</p>
          )}
        </div>
      </main>
    </Container>
  );
};

export default FeaturedProducts;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  justify-content: center;
  margin: 40px 30px;

  main {
    margin-top: 30px;
    .top {
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: start;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
`;
