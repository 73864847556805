import React, { useEffect, useState } from "react";
import api from "../services/api";
import { Skeleton } from "@mui/material";

const ReportedIssues = () => {
  const [reports, setReports] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    api.customer
      .getClaim()
      .then((res) => {
        setReports(res.data.reports);
      })
      .catch((res) => console.log(res))
      .finally(() => setLoading(false));
  }, []);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const entries = Object.values(reports);

  return (
    <div>
      <h1 className="mb-4">Reported Issues</h1>
      <section>
        {loading ? (
          <div>
            {[...Array(5)].map((_, index) => (
              <div key={index} className="mb-4 border border-gray-300 rounded">
                <Skeleton variant="rectangular" height={50} width="100%" />
                <div className="p-4">
                  <Skeleton variant="text" />
                  <Skeleton variant="text" width="60%" />
                </div>
                <div className="accordion-content p-4">
                  <Skeleton variant="text" width="80%" />
                  <Skeleton variant="text" width="60%" />
                  <Skeleton variant="text" width="70%" />
                  <Skeleton variant="rectangular" height={200} />
                  <Skeleton variant="rectangular" height={200} />
                </div>
              </div>
            ))}
          </div>
        ) : reports.length < 1 ? (
          <div>No raised reports yet</div>
        ) : (
          <div>
            {entries.map((report, index) => (
              <div
                key={report.id}
                className="mb-4 border border-gray-300 rounded"
              >
                <button
                  className="w-full p-4 text-left font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none"
                  onClick={() => handleClick(index)}
                >
                  <div>
                    ID: {report.id} - {report.name}
                  </div>
                </button>
                <div
                  className={`accordion-content p-4 bg-white ${
                    activeIndex === index ? "block" : "hidden"
                  }`}
                >
                  <p>
                    <strong>Status:</strong> {report.status}
                  </p>
                  <p>
                    <strong>Reference:</strong> {report.reference}
                  </p>
                  <p>
                    <strong>Email:</strong> {report.email}
                  </p>
                  <p>
                    <strong>Description:</strong> {report.description}
                  </p>
                  <p>
                    <strong>Created At:</strong>{" "}
                    {new Date(report.created_at).toLocaleString()}
                  </p>
                  <p>
                    <strong>Images:</strong>
                  </p>
                  <img
                    src={report.img1}
                    alt="report img1"
                    className="w-32 h-32 object-cover mb-2"
                  />
                  <img
                    src={report.img2}
                    alt="report img2"
                    className="w-32 h-32 object-cover"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </section>
    </div>
  );
};

export default ReportedIssues;
