import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import styled from "styled-components";
import ebn from "../assets/ebn.svg";
import call from "../assets/call.JPG";

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer mt-auto">
      <Container>
        <Address>
          <div className="logo">
            <img src={ebn} alt="ebn-logo" />
            <h3>
              <span className="elect">ELECTRONICS</span>
              <span className="bid"> Bidding</span>
              <span className="nig"> Nigeria</span>
            </h3>
          </div>

          <address>
            Address: Suite 6 Block B, Alausa Shopping Mall, <br />
            131, Obafemi Awolowo way, Ikeja, Lagos.
          </address>
          <p className="call">
            Call Us <a href="tel:+2349130003337">+2349130003337</a>
          </p>
          <a href="mailto:info@itl.ng">Email: info@itl.ng</a>
          <p className="hours">Hours: 10:00 - 18:00 Mon - Sat</p>
        </Address>

        <Company>
          <header>Company</header>

          <div className="links">
            <Link to={"/contact-us"} className="link">
              Contact Us
            </Link>
            <Link
              to="https://drive.google.com/file/d/1BlqBveePctZYPVJ51I001jvZxT5JBs3H/view?usp=sharing"
              className="link"
              target="_blank"
            >
              Terms and Conditions
            </Link>
            <Link
              className="link"
              to="https://drive.google.com/file/d/1wN-e6n_vfh6obhBL2alUMMw50X91kr5j/view?usp=sharing"
              target="_blank"
            >
              Privacy Policy
            </Link>
          </div>
        </Company>

        <Account>
          <header>Account</header>

          <div className="links">
            <Link to="/login" className="link">
              Sign in
            </Link>
            <Link to="/cart" className="link">
              View Cart
            </Link>
            <Link to="/wishlist" className="link">
              My Wishlist
            </Link>
          </div>
        </Account>

        <Corp>
          <header>Corporate</header>

          <div className="links">
            <Link to={"/register"} className="link">
              Become a Vendor
            </Link>
          </div>
        </Corp>
      </Container>

      <div
        className="line"
        style={{ borderTop: "1px solid #3398FF", margin: "0px 30px" }}
      ></div>

      <Info className="add-info">
        <div className="left">
          <p className="copy">
            &#169; {currentYear} <span>EBN</span> HTML E-COMMERCE
          </p>
          <p className="reserved">All Rights Reserved</p>
        </div>

        <div className="help">
          <img src={call} alt="" height={30} width={25} />

          <div className="num-info">
            <a href="tel:+2349130003337" className="num">
              +2349130003337
            </a>
            <p className="center">24/7 Support Center</p>
          </div>
        </div>

        <div className="rights">
          <p>Follow Us</p>
          <div className="right">
            <Link to={"https://www.facebook.com/ebn.nig"} target="_blank">
              <FaFacebook className="social" size={25} color="blue" />
            </Link>
            {/* <FaTwitter className="social" size={25} color="#3398ff" /> */}
            <Link to={"https://www.instagram.com/ebn.nig/"} target="_blank">
              <FaInstagram className="social" size={25} color="#833AB4" />
            </Link>
          </div>
        </div>
      </Info>
    </div>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
  padding: 30px;
`;

const Address = styled.div`
  .logo {
    display: flex;
    align-items: start;
    flex-direction: column;

    h3 {
      text-transform: uppercase;
      font-size: 1rem;
      margin-top: -1px;

      .elect {
        color: #e43137;
      }
      .bid {
        color: #0063d1;
      }
      .nig {
        color: #049b04;
      }
    }
  }

  address,
  p {
    color: rgba(123, 120, 120, 0.8);
    font-weight: 500;
    color:black
  }

  a {
    color:black
    font-weight: 500;
    text-decoration: none;
  }
`;

const Company = styled.div`
  display: flex;
  flex-direction: column;
  color:black

  header {
    font-weight: 700;
    color: #666666;
    font-weight: 18px;
  }

  .links {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 15px;
    color:black

    .link {
      color: rgba(123, 120, 120, 0.8);
      font-weight: 500;
      text-decoration: none;
    }
  }
`;

const Account = styled.div`
  display: flex;
  flex-direction: column;
  color:black

  header {
    font-weight: 700;
    color: #666666;
    font-weight: 18px;
  }

  .links {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 15px;
    color:black

    .link {
      color: rgba(123, 120, 120, 0.8);
      font-weight: 500;
      text-decoration: none;
    }
  }
`;

const Corp = styled.div`
  display: flex;
  flex-direction: column;
  color:black

  header {
    font-weight: 700;
    color: #666666;
    font-weight: 18px;
  }

  .links {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 15px;
    color:black

    .link {
      color: rgba(123, 120, 120, 0.8);
      font-weight: 500;
      text-decoration: none;
    }
  }
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  margin-top: 10px;
  padding-bottom: 10px;
  color:black

  @media (max-width: 430px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
    place-items: start;
    padding: 0px 30px;
  }

  .left {
    color:black;
    font-weight: 500;

    span {
      color: #3398ff;
    }
  }

  .help {
    display: flex;
    align-items: center;
    font-size: 1rem;
    gap: 7px;    

    .num-info {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .num {
        color: #3398ff;
        cursor: pointer;
      }

      .center {
        color:black;
        font-weight: 600;
        margin-top: -15px;
      }
    }
  }

  .rights {
    display: flex;
    flex-direction: column;
    gap: 5px;

    p {
      color:black;
      font-weight: 500;
    }
    .right {
      display: flex;
      align-items: center;
      gap: 20px;

      .social {
        cursor: pointer;
      }
    }
  }
`;
