import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api";

// Async thunk for fetching cart data
export const fetchCart = createAsyncThunk(
  "cart/fetchCart",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.customer.cart.getCart();
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Failed to fetch cart");
      }
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Async thunk for adding an item to the cart
export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      if (!formData) {
        throw new Error("FormData is undefined or null");
      }

      const response = await api.customer.cart.addToCart(id, formData);
      if (response.status === 200) {
        return response.data; // Assuming response contains updated cart info
      } else {
        throw new Error("Failed to add item to cart");
      }
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Async thunk for incrementing the quantity of an item in the cart
export const cartIncrement = createAsyncThunk(
  "cart/cartIncrement",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.customer.cart.cartIncrement(id);
      if (response.status === 200) {
        return response.data; // Should contain updated cart info
      } else {
        throw new Error("Failed to increment item quantity");
      }
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);


// Async thunk for decrementing the quantity of an item in the cart
export const cartDecrement = createAsyncThunk(
  "cart/cartDecrement",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.customer.cart.cartDecrement(id);
      if (response.status === 200) {
        return response.data; // Assuming response contains updated cart info
      } else {
        throw new Error("Failed to decrement item quantity");
      }
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
    cartQty: 0,
    cartTotal: 0,
    status: "idle", // To track request status
    error: null, // To track any error that might occur
  },
  reducers: {
    resetCart: (state) => {
      state.cartItems = [];
      state.cartQty = 0;
      state.cartTotal = 0;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchCart actions
    builder
      .addCase(fetchCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { carts = {}, cartQty = 0, cartTotal = "0" } = action.payload || {};

        // Convert the carts object into an array
        state.cartItems = Object.values(carts);
        state.cartQty = cartQty;
        state.cartTotal = parseFloat(cartTotal.replace(/,/g, "")); // Remove commas and convert to number
      })
      .addCase(fetchCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });

    // Handle addToCart actions
    builder
      .addCase(addToCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { carts = {}, cartQty = 0, cartTotal = "0" } = action.payload || {};

        // Convert the carts object into an array
        state.cartItems = Object.values(carts);
        state.cartQty = cartQty;
        state.cartTotal = parseFloat(cartTotal.replace(/,/g, "")); // Remove commas and convert to number
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });

    // Handle cartIncrement actions
    builder
      .addCase(cartIncrement.pending, (state) => {
        state.status = "loading";
      })
      .addCase(cartIncrement.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { carts = {}, cartQty = 0, cartTotal = "0" } = action.payload || {};
        state.cartItems = Object.values(carts);
        state.cartQty = cartQty;
        state.cartTotal = parseFloat(cartTotal.replace(/,/g, ""));
      })
      .addCase(cartIncrement.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });

    // Handle cartDecrement actions
    builder
      .addCase(cartDecrement.pending, (state) => {
        state.status = "loading";
      })
      .addCase(cartDecrement.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { carts = {}, cartQty = 0, cartTotal = "0" } = action.payload || {};

        // Convert the carts object into an array
        state.cartItems = Object.values(carts);
        state.cartQty = cartQty;
        state.cartTotal = parseFloat(cartTotal.replace(/,/g, "")); 
      })
      .addCase(cartDecrement.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetCart } = cartSlice.actions;

export default cartSlice.reducer;
