import React, { useState, useRef, useEffect } from "react";
import { AiFillEdit } from "react-icons/ai";
import { toast } from "react-toastify";
import api from "../services/api";
import service from "../services";
import { auth } from "../services/api/auth";
import Loading from "../components/Loading";

const AccountProfile = () => {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    photo: '',
    username: "",
  });
  const [updatePassword, setUpdatePassword] = useState({
    current: '',
    newPassword: '',
    confirmNewPassword: ''
  })
  const [hasChanges, setHasChanges] = useState(false);
  const ref = useRef(null);

  const handlePhotoChange = (e) => {
    const selectedFile = e.target.files[0];
    setHasChanges(true);
    setProfile((prev) => ({ ...prev, photo: selectedFile }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
    setHasChanges(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatePassword(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault();
    if (!updatePassword.current || !updatePassword.newPassword || !updatePassword.confirmNewPassword) {
      toast.info('Please fill in all fields');
      return;
    }
    // Check if new password and confirm new password match
    if (updatePassword.newPassword !== updatePassword.confirmNewPassword) {
      toast.error('New password and confirm new password do not match');
      return;
    }
    const formData = new FormData();
    formData.append('current_password', updatePassword.current)
    formData.append('new_password', updatePassword.newPassword)
    formData.append('new_password_confirmation', updatePassword.confirmNewPassword)

    auth.changePassword('user',formData).then((res) => {
      console.log(res);
      if (res.status === 200) {
        toast.success(res.data.message)
        setUpdatePassword(
          {
            current: '',
            newPassword: '',
            confirmNewPassword: ''
          }
        )
      }
    }).catch((err) => {
      console.lor(err, "na here e be");
      if (err.response.status === 422) {
        toast.error(err.response.data.message)
      }
    }).finally(()=>setLoading(false))
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("name", profile.name);
    formData.append("phone", profile.phone);
    formData.append("address", profile.address);
    formData.append("photo", profile.photo);
    formData.append("username", profile.username);

    api.customer.profile
      .updateProfile(formData)
      .then((res) => {
        setHasChanges(false);
        toast.success("Profile Updated Successfully", { autoClose: 2000 });
        service.reloadPage()
      })
      .catch((res) => console.log(res));
  };

  useEffect(() => {
    api.customer.profile
      .get()
      .then((res) => {
        setProfile(res.data?.user);
      })
      .catch((res) => console.log(res));
  }, []);

  return (
    <section className="bg-gray-100 w-auto p-5 ">
      <h3 className="text-xl font-bold text-blue-600">Edit your Profile</h3>
      <aside className="profile_picture my-4">
        <figure className="relative">
          <img
            src={"https://ebn.itl.ng/public/upload/user_images/" + profile?.photo}
            alt="profile"
          />
          <AiFillEdit
            className="absolute z-50 top-[4px] left-[4px] cursor-pointer"
            onClick={() => ref.current.click()}
          />
          <input
            type="file"
            name="photo"
            id="photo"
            accept=".jpg, .jpeg, .png"
            ref={ref}
            className="hidden"
            onChange={handlePhotoChange}
          />
        </figure>
      </aside>
      <form>
        {/* main details */}
        <section className="grid grid-cols-2 gap-3">
          <div className="account-input">
            <label htmlFor="name">Username</label>
            <input
              type="text"
              name="username"
              id="username"
              className="input"
              value={profile.username}
              disabled
              onChange={handleInputChange}
            />
          </div>
          <div className="account-input">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              className="input"
              value={profile.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="account-input">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              className="input"
              value={profile.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="account-input">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              name="phone"
              id="phone"
              className="input"
              value={profile.phone}
              onChange={handleInputChange}
            />
          </div>
          <div className="account-input">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              name="address"
              id="address"
              className="input"
              value={profile.address}
              onChange={handleInputChange}
            />
          </div>
        </section>
        <div className="flex gap-3 w-full justify-end my-4">
          <button
            type="button"
            className={!hasChanges ? "disabled" : "submitBtn"}
            disabled={!hasChanges}
            onClick={handleUpdate}
          >
            Update
          </button>
          <button
            type="button"
            className={!hasChanges ? "disabled" : "cancel"}
            disabled={!hasChanges}
            onClick={() => setHasChanges(false)}
          >
            Cancel
          </button>
        </div>

        <section className="mt-4">
          <h3 className="text-xl font-medium mb-2">Password Changes</h3>
          <div className="flex flex-col gap-3 w-full">
            <input
              type="password"
              name="current"
              id="current password"
              className="input"
              placeholder="Current Password"
              value={updatePassword.current}
              onChange={handleChange}
            />
            <input
              type="password"
              name="newPassword"
              id="new password"
              className="input"
              placeholder="New Password"
              value={updatePassword.newPassword}
              onChange={handleChange}
            />
            <input
              type="password"
              name="confirmNewPassword"
              id="confirm new password"
              className="input"
              placeholder="Confirm New Password"
              value={updatePassword.confirmNewPassword}
              onChange={handleChange}
            />
          </div>
        </section>
        <button
          type="button"
          value="Save Changes"
          onClick={handleSubmit}
          disabled={loading}
          className="bg-blue-600 py-3 px-6 ml-auto mt-3 text-white rounded-md flex"
        >{loading ? <Loading /> : "Change"}</button>
      </form>
    </section>
  );
};

export default AccountProfile;
