import {
  Button,
  Chip,
  CircularProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Navigation, Pagination } from "swiper/modules";
import api from "../services/api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formatPrice } from "../components/FormatPrice";
import { toast } from "react-toastify";
import { addToCart, fetchCart } from "../redux/Features/cartSlice";
import { useDispatch } from "react-redux";
import NewProductCard from "../components/Cards/NewProductCard";
import Modal from "../components/Cards/Modal";
import CommentsSection from "../components/CommentsSection";

const ProductPage = () => {
  const [adding, setAdding] = useState(false);
  const [product, setProduct] = useState({});
  const [multiImg, setMultiImg] = useState([]);
  const { productId } = useParams();
  const [otherDetails, setOtherDetails] = useState({
    brand: "",
    category: "",
    subCategory: "",
  });
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [related, setRelated] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    // get product by ID
    api.customer.products
      .getProduct(productId)
      .then((res) => {
        setProduct(res.data?.Product);
        setMultiImg(res.data?.MultiImage);
        setLoading(false);
      })
      .catch((res) => {
        console.log(res);
        setLoading(false);
      });
  }, [productId]);

  useEffect(() => {
    const fetchProductDetails = async () => {
      if (product) {
        setLoading(true);

        try {
          // Fetch brand and category in parallel
          const brandPromise = product.brand_id
            ? api.customer.brand.getBrand(product.brand_id)
            : Promise.resolve();
          const categoryPromise = product.category_id
            ? api.customer.category.getCategory(product.category_id)
            : Promise.resolve();

          const [brandRes, categoryRes] = await Promise.all([
            brandPromise,
            categoryPromise,
          ]);

          if (brandRes?.status === 200) {
            setOtherDetails((prev) => ({
              ...prev,
              brand: brandRes.data.Brand.brand_name,
            }));
          }

          if (categoryRes?.status === 200) {
            setOtherDetails((prev) => ({
              ...prev,
              category: categoryRes.data.Category.category_name,
            }));
          }

          // Fetch related products
          const productsRes = await api.customer.products.getProducts();

          if (productsRes.status === 200) {
            const products = productsRes.data.products;

            if (!products || products.length === 0) {
              setRelated([]);
              return;
            }

            const filteredProducts = products.filter((relate) => {
              return (
                relate.category_id === product.category_id &&
                relate.id !== product.id
              );
            });
            setRelated(filteredProducts);
          }
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      }
    };

    const fetchReviews = () =>{
      api.customer.review.getReviews(product.id).then(res=>setReviews(res.data?.data)).catch(console.error)
    }

    fetchReviews()
    fetchProductDetails();
  }, [product]);

  const finalPrice =
    product.discount_price > 0 ? product.discount_price : product.selling_price;

  // Parse JSON fields safely
  const productTags = product.product_tags
    ? JSON.parse(product.product_tags)
    : [];
  const productSizes = product.product_size
    ? JSON.parse(product.product_size)
    : [];
  const productColors = product.product_color
    ? JSON.parse(product.product_color)
    : [];

  const addingToCart = () => {
    setAdding(true);

    const { id } = product;

    if (selectedColor === "" || selectedSize === "") {
      toast.warn("Kindly add a color or a size");
      setAdding(false);
      return;
    }
    const formData = new FormData();
    formData.append("product_name", product.product_name);
    formData.append("quantity", 1);
    formData.append("color", selectedColor);
    formData.append("size", selectedSize);
    formData.append("vendor", product.vendor_id);

    // Dispatch the thunk action
    dispatch(addToCart({ id, formData }))
      .unwrap()
      .then((res) => {
        toast.success(`${product.product_name} added to cart`);
        dispatch(fetchCart());
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Failed to add ${product.product_name} to cart`);
      })
      .finally(() => setAdding(false));
  };

  // popup product modal
  const [selectedProduct, setSelectedProduct] = useState(null);
  let sizes = [];
  if (selectedProduct && selectedProduct.product_size) {
    try {
      sizes = JSON.parse(selectedProduct.product_size);
    } catch (error) {
      console.error("Error parsing product sizes:", error);
    }
  }
  let colors = [];
  if (selectedProduct && selectedProduct?.product_color) {
    try {
      colors = JSON.parse(selectedProduct?.product_color);
    } catch (error) {
      console.error("Error parsing product sizes:", error);
    }
  }

  const handleView = (productId) => {
    const product = related.find((p) => p.id === productId);
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const [details, setDetails] = useState({
    size: "",
    quantity: 1,
    color: "",
  });

  const handleDetails = (e) => {
    const { name, value } = e.target;
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="w-full md:w-[80%] md:mx-auto p-4 mt-6">
      {/* Main Content Wrapper */}
      <div className="md:flex gap-3">
        {/* Left - Product Images */}
        <div className="md:w-1/2">
          {loading ? (
            <Skeleton variant="rectangular" height={400} />
          ) : (
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              modules={[Navigation, Pagination]}
            >
              {multiImg?.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={"https://api.ebn.ng/public" + image?.photo_name}
                    alt={`product-${index}`}
                    className="w-full h-[300px] object-contain rounded-md shadow-lg"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>

        {/* Right - Product Details */}
        <div className="md:w-1/2 p-4">
          {loading ? (
            <>
              <Skeleton variant="text" width="80%" height={40} />
              <Skeleton variant="text" width="40%" height={30} />
              <Skeleton variant="text" width="60%" height={30} />
              <Skeleton variant="text" width="40%" height={30} />
              <Skeleton variant="rectangular" height={300} />
            </>
          ) : (
            <>
              {/* Product Name */}
              <h1 className="text-2xl font-bold mb-2">
                {product?.product_name}
              </h1>
              <div className="flex gap-3 items-center">
                <h3 className="text-xl lg:text-2xl my-2 font-bold">
                  ₦{formatPrice(finalPrice)}
                </h3>
                {product?.discount_price > 0 && (
                  <h3 className="text-xl lg:text-2xl my-2 font-bold text-red-900">
                    ₦<strike>{formatPrice(product.selling_price)}</strike>
                  </h3>
                )}
              </div>

              {/* Brand and Product Code */}
              <p className="text-gray-600 mb-1">
                Brand:{" "}
                <span className="font-semibold">{otherDetails?.brand}</span>
              </p>
              <p className="text-gray-600 mb-1">
                Category:{" "}
                <span className="font-semibold">{otherDetails?.category}</span>
              </p>

              {/* Tags */}
              <div className="mb-3">
                {productTags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    variant="outlined"
                    className="mr-2"
                  />
                ))}
              </div>

              {/* Product Size and Color */}
              <div className="mb-3">
                <p className="font-semibold">Available Sizes:</p>
                <div className="space-x-2">
                  {productSizes.map((size, index) => (
                    <Button
                      key={index}
                      variant={selectedSize === size ? "contained" : "outlined"} // Highlight selected size
                      className="capitalize"
                      onClick={() => setSelectedSize(size)} // Update selected size on click
                    >
                      {size}
                    </Button>
                  ))}
                </div>
              </div>

              <div className="mb-3">
                <p className="font-semibold">Available Colors:</p>
                <div className="space-x-2">
                  {productColors.map((color, index) => (
                    <span
                      key={index}
                      className={`w-6 h-6 inline-block rounded-full border border-gray-400 cursor-pointer ${
                        selectedColor === color ? "ring-2 ring-blue-500" : ""
                      }`}
                      style={{ backgroundColor: color }}
                      onClick={() => setSelectedColor(color)}
                    ></span>
                  ))}
                </div>
              </div>

              {/* Add to Cart Button */}
              <Button
                variant="contained"
                color="primary"
                className="w-full"
                onClick={addingToCart}
                disabled={adding === true || product?.product_qty < 1}
              >
                {adding ? (
                  <CircularProgress size={20} sx={{ color: "whitesmoke" }} />
                ) : product?.product_qty < 1 ? (
                  "Out of Stock"
                ) : (
                  "Add to Cart"
                )}
              </Button>
            </>
          )}
        </div>
      </div>

      {/* Product Description */}
      <div className="mt-6">
        <h2 className="text-lg font-semibold mb-2">Product Description</h2>
        {loading ? (
          <>
            <Skeleton variant="text" height={40} />
            <Skeleton variant="text" height={30} />
            <Skeleton variant="rectangular" height={100} />
          </>
        ) : (
          <div
            className="prose"
            dangerouslySetInnerHTML={{ __html: product.long_description }}
          />
        )}
      </div>

      <CommentsSection loading={loading} setLoading={setLoading} comments={reviews} id={productId} vendor_id={product?.vendor_id}/>

      <section className="mt-[30px]">
        <h2 className="text-2xl ">
          <span className="border-l-[15px] rounded-md mr-2 border-l-green-700"></span>
          Related Products
        </h2>
        <div className="related">
          {loading ? (
            // Loading state
            <div className="flex justify-center items-center">
              <CircularProgress />
            </div>
          ) : related?.length === 0 ? (
            // Empty state
            <Typography variant="h6" align="center" className="mt-4">
              No related products found.
            </Typography>
          ) : (
            // Render related products
            related?.map((product) => {
              return (
                <NewProductCard
                  onView={handleView}
                  key={product.id}
                  price={product.selling_price}
                  product_name={product.product_name}
                  rating={product.productRating}
                  image={
                    "https://api.ebn.ng/public" + product.product_thumbnail
                  }
                  id={product.id}
                  tag={product.productTag}
                  discount={product.discount_price}
                  createdBy={product.created_by}
                  slug={product.product_slug}
                />
              );
            })
          )}
        </div>
      </section>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        {selectedProduct && (
          <section className="flex flex-col lg:flex-row gap-8 mt-[50px] w-[80%] mx-auto">
            {/* Product slider */}
            <div className="w-full lg:w-[40%]">
              <img
                src={
                  "https://api.ebn.ng/public" +
                  selectedProduct.product_thumbnail
                }
                alt={"just"}
                className="w-full  object-contain"
              />
            </div>
            {/* Product Details */}
            <aside className="w-full lg:w-[55%]">
              <h3 className="text-xl lg:text-2xl w-full">
                {selectedProduct?.product_name}
              </h3>

              <div className="flex flex-col gap-4 my-6">
                <article className="flex gap-3 items-center justify-between">
                  <p>Size :</p>
                  <select
                    name="size"
                    id="size"
                    value={details.size}
                    className="w-[80%]"
                    onChange={handleDetails}
                  >
                    <option value="">Select a size</option>
                    {sizes.map((size) => {
                      return (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      );
                    })}
                  </select>
                </article>
                <article className="flex gap-3  items-center justify-between">
                  <p>Color :</p>
                  <select
                    name="color"
                    id="color"
                    value={details.color}
                    onChange={handleDetails}
                    className="w-[80%]"
                  >
                    <option value="">Select a color</option>
                    {colors.map((color) => {
                      return (
                        <option key={color} value={color}>
                          {color}
                        </option>
                      );
                    })}
                  </select>
                </article>
              </div>

              {/* the ratings and reviews with price section */}
              <div className="mt-[20px]">
                {/* details */}
                <div className="flex gap-3 items-center">
                  <h3 className="text-xl lg:text-2xl my-2 font-bold">
                    ₦{formatPrice(finalPrice)}
                  </h3>
                  {selectedProduct.discount_price > 0 && (
                    <h3 className="text-xl lg:text-2xl my-2 font-bold text-red-900">
                      ₦
                      <strike>
                        {formatPrice(selectedProduct.selling_price)}
                      </strike>
                    </h3>
                  )}
                </div>
                {/* extra details */}
                <div>
                  {/* buttons redux */}
                  <div className="mt-[20px]">
                    <div className="mt-4">
                      <button
                        type="button"
                        disabled={adding === true}
                        onClick={addingToCart}
                        className={`py-3 px-5 bg-green-700 text-white font-medium rounded-md ${
                          adding || product?.product_qty < 1
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                      >
                        {adding ? (
                          <CircularProgress
                            size={20}
                            sx={{ color: "whitesmoke" }}
                          />
                        ) : product?.product_qty < 1 ? (
                          "Out of Stock"
                        ) : (
                          "Add to Cart"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </section>
        )}
      </Modal>
    </div>
  );
};

export default ProductPage;
