import CustomText from "./CustomText";

const CustomButton = ({
 text,
 disabled,
 leftIcon,
 rightIcon,
 width,
 height,
 paddingTop,
 paddingBottom,
 paddingLeft,
 paddingRight,
 textWeight,
 onClick,
 loading,
 color,
 background,
 border,
 borderRadius,
 fontSize,
 iconSize,
 className,
}) => {
 return (
  <button
   disabled={disabled}
   style={{
    opacity: disabled ? "40%" : "100%",
    backgroundColor: background,
    borderRadius: borderRadius,
    textAlign: "center",
    width: width,
    height: height,
    paddingTop: paddingTop,
    paddingBottom: paddingBottom,
    paddingLeft: paddingLeft,
    paddingRight: paddingRight,
    color: color,
    border: border,
    fontSize: fontSize,
   }}
   className={className}
   onClick={loading || disabled ? () => null : onClick}
  >
   <div>
    {leftIcon && (
     <img
      src={leftIcon}
      alt="left-icon"
      style={{ width: iconSize, height: "auto" }}
     />
    )}
   </div>
   <div>
    <CustomText text={text} className="text-center" weight={textWeight} />
   </div>
   <div>
    {rightIcon && (
     <img
      src={rightIcon}
      alt="right-icon"
      style={{ width: iconSize, height: "auto" }}
     />
    )}
   </div>
  </button>
 );
};

export default CustomButton;
