import { Link, Outlet } from "react-router-dom";
import { useState } from "react";

const Account = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <main className="flex flex-col gap-4 md:px-4 mb-8 py-4 w-full bg-gray-50">
      <section className="flex flex-col md:flex-row gap-10 px-4 mb-8 py-4 justify-center lg:w-[90%] mx-auto">
        {/* Toggle button for mobile view */}
        <div className="md:hidden w-[90%] mx-auto flex">
          <button
            className="p-2 mb-4 bg-blue-500 text-white rounded"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            {isSidebarOpen ? "Hide Menu" : "Show Menu"}
          </button>
        </div>
        
        {/* Sidebar */}
        <aside className={`bg-gray-100 p-4 flex flex-col gap-5 ${isSidebarOpen ? 'block' : 'hidden'} md:block md:w-1/4`}>
          <article className="account">
            <h3 className="account-header">Manage My Account</h3>
            <ul>
              <Link to={"/myaccount/profile"} onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                <li>My Profile</li>
              </Link>
            </ul>
          </article>
          <article className="account">
            <h3 className="account-header">History</h3>
            <ul>
              <Link to="/myaccount/profile/transaction-history" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                <li>Transaction History</li>
              </Link>
              <Link to="/myaccount/profile/bidding-history" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                <li>Bidding History</li>
              </Link>
            </ul>
          </article>
          <article className="account">
            <h3 className="account-header">Report</h3>
            <ul>
              <Link to="/myaccount/profile/raise-claim" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                <li>Make a report</li>
              </Link>
              <Link to="/myaccount/profile/issues" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                <li>Reported Issues</li>
              </Link>
            </ul>
          </article>
        </aside>

        {/* Main content */}
        <section className="w-full md:w-3/4">
          <Outlet />
        </section>
      </section>
    </main>
  );
};

export default Account;