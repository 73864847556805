import axios from "axios";
import routes from "../routes";

export const auth = {
 registerUser: async (postBody) => {
  return await axios.post(routes.register("user"), postBody);
 },
 registerVendor: async (postBody) => {
  return await axios.post(routes.register("vendor"), postBody);
 },
 login: async (postBody) => {
  return await axios.post(routes.login("user"), postBody);
 },
 getAllBrands: async () => {
  return await axios.get(routes.admin["get-brands"]());
 },
 getUserProfile: async () => {
  return await axios.get(routes.customer.profile["get-details"]());
 },
 refreshToken: async (token) => {
  return await axios.post(routes["refresh-token"](),token);
 },
 resetPassword: async(postBody)=>{
  return await axios.post(routes.customer["reset-password"](),postBody)
 },
 activatePassword: async(postBody)=>{
  return await axios.post(routes.customer["activate-resetPassword"](),postBody)
 },
 changePassword: async(userType,postBody)=>{
  return await axios.post(routes["change-password"](userType),postBody)
 }
};
