import React from "react";
import { Rating } from "@mui/material";
import { formatDate } from "./FormatPrice";

const Comment = ({ name, date, content, rating, avatar }) => (
  <div className="p-4 border-b border-gray-200">
    {/* Avatar and user info */}
    <div className="flex items-center space-x-4 mb-3">
      <img
        src={avatar}
        alt={name}
        className="w-12 h-12 rounded-full object-cover"
      />
      <div>
        <h4 className="font-semibold">{name}</h4>
        <p className="text-sm text-gray-500">{formatDate(date)}</p>
      </div>
    </div>

    {/* Rating */}
    <div className="mb-2">
      <Rating value={rating} readOnly precision={0.5} />
    </div>

    {/* Comment content */}
    <p>{content}</p>
  </div>
);

export default Comment;
