import React from "react";
import styled from "styled-components";
import CategoryBox from "../CategoryBox";
import { shuffleArray } from "../FormatPrice";
import smallbanner from '../../assets/smallbanner.png'

const BottomFeaturedCategory = () => {
 const shuffledArray = shuffleArray([1,2,3,4,5,6]).slice(0, 3)

 return (
  <Container>
    <div className="border-2 border-[#0063D1] w-full">
    <article className="parallelogram bg-[#0063D1]">
        <h1 className="text-white font-semibold">Shop now</h1>
    </article>
    </div>
   <div className="fashion">
    <div>
     {shuffledArray.map((val) => (
      <CategoryBox
       key={val.id}
       id={val.id}
       backgroundColor="#f0e8d5"
       prodimage={smallbanner}
       descr={val.product_name}
      />
     ))}
    </div>
   </div>
  </Container>
 );
};

export default BottomFeaturedCategory;

const Container = styled.div`
 margin: 0 30px;
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
 gap: 2rem;

 .fashion,
 .elect,
 .cars {
  width: 95%;
  margin: 0 auto;
  overflow-x: scroll;

  .category-header {
   font-size: 25px;
   font-weight: 600;
   padding-bottom: 15px;
   color: #049b04;
  }

  div {
   display: flex;
   justify-content: start;
   align-items: flex-start;
   gap: 30px;
  }
 }
 @media (min-width: 1200px) {
  .fashion,
  .elect,
  .cars {
   width: 100%;
   overflow-x: scroll;

   &::-webkit-scrollbar {
    display: none;
   }

   div {
    justify-content: start;
    align-items: flex-start;
   }
  }
 }
`;
