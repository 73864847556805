import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 wishListItems: [],
 wishes: 0,
};

const wishlistSlice = createSlice({
 name: "wishlist",
 initialState,
 reducers: {
  addToWishlist: (state, action) => {
    state.wishListItems.push(action.payload);
    state.wishes += 1;
  },
  removeFromWishlist: (state, action) => {
   const itemId = action.payload;
   state.wishListItems = state.wishListItems.filter(
    (item) => item.id !== itemId
   );
   state.wishes -= 1;
    // if(state.wishes === 1){
    //  state.wishListItems = [];
    //  state.wishes = 0;
    // }
  },
  clearWishlist: (state) => {
   state.wishListItems = [];
   state.wishes = 0;
  },
 },
});

export const { addToWishlist, removeFromWishlist, clearWishlist } =
 wishlistSlice.actions;

export default wishlistSlice.reducer;
