import React, { useState, useEffect } from "react";
import api from "../services/api";
import ReactPaginate from "react-paginate";
import { formatDate, formatPrice } from "../components/FormatPrice";
import { CircularProgress } from "@mui/material";

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [currentPage, setCurrentPage] = useState(0); // Current page for pagination
  const [totalTransactions, setTotalTransactions] = useState(0); // Total transactions for pagination

  // Fetch transactions from API
  useEffect(() => {
    setLoading(true); // Start loading when fetching
    api.customer.profile
      .getTransactions()
      .then((res) => {
        console.log(res);
        setTransactions(res.data?.Transactions || []); // Set the transactions
        setTotalTransactions(res.data?.Transactions?.length || 0); // Total number of transactions
        setLoading(false); // Stop loading when done
      })
      .catch((err) => {
        console.log(err);
        setLoading(false); // Stop loading if there's an error
      });
  }, []);

  // Handle page change for pagination
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  // Paginate the data (you can customize the items per page)
  const itemsPerPage = 5;
  const paginatedTransactions = transactions.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <section>
      <div>
        <h1 className="text-2xl">Transaction History</h1>
      </div>
      <div className="px-3 overflow-auto my-4 w-full">
        {/* Show loading indicator while fetching */}
        {loading ? (
          <div className="flex justify-center items-center">
            <CircularProgress />
          </div>
        ) : (
          <table className="divide-y divide-gray-200 min-w-full">
            <thead>
              <tr className="tableHeadRow">
                <th className="tableHead">Order number</th>
                <th className="tableHead">Paystack ref</th>
                <th className="tableHead">Date </th>
                <th className="tableHead">Total price</th>
                <th className="tableHead">Status</th>
              </tr>
            </thead>
            <tbody>
              {paginatedTransactions.length > 0 ? (
                paginatedTransactions.map((x, y) => (
                  <tr className="tableRow py-2" key={x.id + y}>
                    <td>{x.order_number}</td>
                    <td>{x.paystack_ref}</td>
                    <td>{formatDate(x.created_at)}</td>
                    <td>{formatPrice(x.total_price / 100)}</td>
                    <td>Delivered</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No transactions available.</td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {/* Pagination Controls */}
        {!loading && totalTransactions > itemsPerPage && (
          <ReactPaginate
            previousLabel={"⇽"}
            nextLabel={"⇾"}
            breakLabel={"..."}
            pageCount={Math.ceil(totalTransactions / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="paginateContainer"
            pageClassName="cursor-pointer py-1 px-2 rounded-full bg-gray-200 font-bold hover:bg-green-500 hover:text-white"
            previousClassName="nextPrev"
            nextClassName="nextPrev"
            activeClassName="bg-green-500 text-white"
          />
        )}
      </div>
    </section>
  );
};

export default TransactionHistory;
