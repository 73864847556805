import { configureStore } from "@reduxjs/toolkit";
import wishReducer from "./Features/wishlistSlice";
import cartReducer from "./Features/cartSlice";
import authReducer from "./Features/authSlice";

export const store = configureStore({
 reducer: {
  cart: cartReducer,
  wishlist: wishReducer,
  auth: authReducer,
 },
});
