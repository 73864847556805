export const formatPrice = (amount) => {
  const sanitizedAmount = String(amount).replace(/,/g, ''); // Convert to string and remove commas
  const parsedAmount = parseFloat(sanitizedAmount);

  if (isNaN(parsedAmount)) {
    return "Invalid Amount";
  }

  return parsedAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};



export const shuffleArray = (array) => {
 const shuffledArray = [...array];
 for (let i = shuffledArray.length - 1; i > 0; i--) {
   const j = Math.floor(Math.random() * (i + 1));
   [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
 }
 return shuffledArray;
};

export function formatDate(isoDateString) {
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}