import styled from "styled-components";
import NewProductCard from "../Cards/NewProductCard";

const NewProduct = ({ onView, products }) => {
  return (
    <Container>
      <header>
        <div className="border-2 border-[#B5F6B5] w-full">
          <article className="parallelogram bg-[#B5F6B5]">
            <h1 className="text-white font-semibold">New Products</h1>
          </article>
        </div>
      </header>

      <main>
        <div className="top">
          {products?.slice(0, 5).map((product, idx) => (
            <NewProductCard
              onView={onView}
              key={idx}
              price={product.selling_price}
              product_name={product.product_name}
              rating={product.productRating}
              image={"https://api.ebn.ng/public" + product.product_thumbnail}
              id={product.id}
              tag={product.productTag}
              discount={product.discount_price}
              createdBy={product.created_by}
              slug={product.product_slug}
              {...product}
            />
          ))}
        </div>
      </main>
    </Container>
  );
};

export default NewProduct;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  justify-content: center;
  margin: 40px 30px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;


    .links {
      display: flex;
      align-items: center;
      gap: 15px;

      .nav-link {
        text-decoration: none;
        font-size: 18px;
        color: #000;
      }

      .nav-link:hover {
        color: #049b04;
        border-bottom: 1px solid #049b04;
      }
    }
    @media (max-width: 430px) {
    }
  }

  main {
    .top {
      display: flex;
      flex-direction: row;
      gap:10px;
      justify-content: start;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 20px;
    }
    @media (max-width: 430px) {
      .top {
        display: flex;
        gap: 2rem;
        overflow-x: scroll;
        padding-bottom: 20px;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
`;
