import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Grid,
  CircularProgress,
  // Snackbar,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import api from "../services/api";
import EmptyStateImage from "../assets/cart.png";
// import { useSearchParams } from "react-router-dom";

const BidsWon = () => {
  const [wonBids, setWonBids] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isPaying, setIsPaying] = useState(false);
  // const [isConfirming, setIsConfirming] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [openSnackbar, setOpenSnackbar] = useState(false);

  // const [query] = useSearchParams();
  // // const ref = query.get("reference");
  // // const trxref = query.get("trxref");
  // // const bidId = query.get("bidId");

  useEffect(() => {
    const fetchWonBids = async () => {
      try {
        const response = await api.customer.bidding.getWinnings();
        setWonBids(response.data.Winnings);
      } catch (error) {
        toast.error("Failed to fetch won bids");
      } finally {
        setIsFetching(false);
      }
    };

    fetchWonBids();
  }, []);

  // const handlePayment = async (bidId) => {
  //   setIsPaying(true);
  //   try {
  //     const response = await api.customer.bidding.initializeAuctionPayment(
  //       bidId
  //     );
  //     const url = response.data?.authorization_url;
  //     window.location.href = url;
  //     setSnackbarMessage("Pay for your bid!");
  //     setOpenSnackbar(true);
  //   } catch (error) {
  //     setIsPaying(false);
  //     toast.error("Payment failed. Please try again.");
  //   } finally {
  //     setIsPaying(false);
  //   }
  // };

  // const handleConfirmation = useCallback(async () => {
  //   setIsConfirming(true);
  //   const formData = new FormData();
  //   formData.append("reference", ref);
  //   try {
  //     await api.customer.bidding.confirmAuctionPayment(bidId, formData);
  //     setSnackbarMessage("Payment confirmed!");
  //     setOpenSnackbar(true);
  //     const response = await api.customer.bidding.getWinnings();
  //     setWonBids(response.data.Winnings);
  //   } catch (error) {
  //     toast.error("Payment confirmation failed. Please try again.");
  //   } finally {
  //     setIsConfirming(false);
  //   }
  // }, [ref, bidId]); // Include ref in dependencies

  // useEffect(() => {
  //   if (trxref) {
  //     handleConfirmation();
  //   }
  // }, [trxref, handleConfirmation]);

  const addBidToCart = (id) => {
    setIsPaying(true)
    api.customer.bidding
      .addAuctionToCart(id)
      .then((res) => {
        toast.success("Added to cart");
      })
      .catch(console.error);
  };

  return (
    <section className="m-4 md:mx-[3%]">
      <Typography variant="h4" align="center" sx={{ mb: 3 }}>
        Won Bids
      </Typography>
      {isFetching ? (
        <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={2}>
          {wonBids?.length === 0 ? (
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                sx={{ py: 4 }}
              >
                <Box
                  component="img"
                  src={EmptyStateImage}
                  alt="No Bids Won"
                  sx={{ width: { xs: "60%", sm: "40%", md: "30%" }, mb: 2 }}
                />
                <Typography variant="h5" color="text.secondary">
                  No bids won yet.
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  It looks like you haven't won any bids.
                  <br /> Check back later or join a new auction!
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => (window.location.href = "/auctions")}
                >
                  Browse Auctions
                </Button>
              </Box>
            </Grid>
          ) : (
            wonBids.map((bid) => (
              <Grid item xs={12} md={6} lg={4} key={bid.id}>
                <Card className="shadow-lg" sx={{ maxWidth: "400px" }}>
                  <CardContent>
                    <Typography variant="h5">{bid.product_name}</Typography>
                    <Typography variant="h6" color="green">
                      Winning Bid: ₦{bid.auction_price}
                    </Typography>
                    <Typography variant="body1">
                      Auction Ended On:{" "}
                      {new Date(bid.end_time).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" className="mb-2">
                      Item Description:
                    </Typography>
                    <p className="text-wrap truncate w-[390px']">
                      {bid.description}
                    </p>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => addBidToCart(bid.id)}
                      sx={{ marginTop: 1 }}
                      disabled={bid.payment_status !== "unpaid" || isPaying}
                    >
                      {bid.payment_status === "unpaid"
                        ? "Add to cart"
                        : "Paid"}
                      {isPaying && <CircularProgress size={24} />}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      )}
      {/* <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      /> */}
      {/* {isConfirming && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bgcolor="rgba(255, 255, 255, 0.8)"
          zIndex={1000}
        >
          <CircularProgress />
          <Typography variant="h6" sx={{ ml: 2 }}>
            Confirming Payment...
          </Typography>
        </Box>
      )} */}
    </section>
  );
};

export default BidsWon;
