import React, { useRef } from "react";
import { formatPrice } from "./FormatPrice";
import EBN from "../assets/editedebn.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas"; // Import html2canvas
import { useNavigate } from "react-router-dom";

const Receipt = ({ transactionDetails, targetRef }) => {
  const navigate = useNavigate();
  const receiptRef = useRef(null); // Create a ref to the receipt container

  const downloadPDF = async () => {
    const doc = new jsPDF();

    try {
      // Use html2canvas to capture the content of the receiptRef (the whole receipt component)
      const canvas = await html2canvas(receiptRef.current, {
        useCORS: true, // Ensure CORS support for external images
        scale: 2, // Increase the scale for better resolution
        logging: true, // Enable logging for debugging (optional)
        width: receiptRef.current.offsetWidth, // Capture full width
        height: receiptRef.current.offsetHeight, // Capture full height
      });

      // Convert the canvas to an image (Base64 encoded PNG)
      const imgData = canvas.toDataURL("image/png");

      // Add the image to the PDF, starting at coordinates (10, 10)
      doc.addImage(imgData, "PNG", 10, 10, 190, 0); // Adjust the size as needed

      // Save the PDF
      doc.save("receipt.pdf");

      // Redirect after download (optional)
      navigate("/");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const containerStyle = {
    backgroundColor: "#fff",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    maxWidth: "90%",
    margin: "0 auto",
    borderRadius: "8px",
    position: "relative",
    width: "100%",
  };

  const headingStyle = {
    fontSize: "2.5rem",
    fontWeight: "bold",
    marginBottom: "1rem",
    textAlign: "center",
  };

  const paragraphStyle = {
    fontSize: "1.2rem",
    color: "#4B5563",
    marginBottom: "0.5rem",
  };

  const itemHeadingStyle = {
    fontSize: "1.4rem",
    fontWeight: "bold",
    marginBottom: "0.75rem",
  };

  const listItemStyle = {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    justifyContent: "space-between",
    borderBottom: "2px solid gray",
    marginBottom: "20px",
    paddingBottom: "10px",
    width: "400px",
  };

  const productsStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "20px",
    margin: "50px 0 auto auto",
  };

  const totalStyle = {
    fontSize: "1.25rem",
    fontWeight: "bold",
    color: "#10B981",
  };

  return (
    <div style={containerStyle} ref={receiptRef}>
      <img
        src={EBN}
        alt="logo"
        style={{ margin: "10px auto", display: "flex", width: "250px" }}
      />
      <h2 style={headingStyle}>Receipt</h2>
      <div style={{ marginBottom: "1rem" }}>
        <p style={paragraphStyle}>
          Date:{" "}
          <span style={{ fontWeight: "bold" }}>
            {transactionDetails?.order_date}
          </span>
        </p>
        <p style={paragraphStyle}>
          Invoice No:{" "}
          <span style={{ fontWeight: "bold" }}>
            {transactionDetails?.invoice_no}
          </span>
        </p>
        <p style={paragraphStyle}>
          Order No:{" "}
          <span style={{ fontWeight: "bold" }}>
            {transactionDetails?.order_number}
          </span>
        </p>
        <p style={paragraphStyle}>
          Product Quantity:{" "}
          <span style={{ fontWeight: "bold" }}>
            {transactionDetails?.products?.length}
          </span>
        </p>
        <p style={paragraphStyle}>
          Amount:{" "}
          <span style={{ fontWeight: "bold" }}>
            ₦{formatPrice(transactionDetails?.amount_paid)}
          </span>
        </p>
      </div>
      <div>
        <h3 style={itemHeadingStyle}>Products:</h3>
        <ul style={productsStyle}>
          {transactionDetails?.items?.map((item, index) => (
            <li key={index} style={listItemStyle}>
              <div style={{ position: "relative", width: "20%" }}>
                <img
                  src={"https://api.ebn.ng/public" + item.img_url}
                  alt={item.product_name}
                  className="w-[80px] h-[80px]"
                />
                <small
                  style={{
                    position: "absolute",
                    top: "0",
                    backgroundColor: "gray",
                    padding: "2px 3px",
                    borderRadius: "1000px",
                  }}
                >
                  {item?.qty}
                </small>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "80%",
                  marginRight: "0px",
                }}
              >
                <span style={{ width: "95%", fontSize: "17px" }}>
                  {item?.product_name}
                </span>
                <div style={{ display: "flex", gap: "20px" }}>
                  <span style={{ fontSize: "17px" }}>Color: {item?.color}</span>
                  <span style={{ fontSize: "17px" }}>Size: {item?.size}</span>
                </div>
                <span style={{ fontWeight: "bolder" }}>
                  ₦{formatPrice(item?.price)}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div style={{ marginTop: "1rem" }}>
        <p style={totalStyle}>
          Total:{" "}
          <span style={{ color: "#059669" }}>
            ₦{formatPrice(transactionDetails.amount_paid)}
          </span>
        </p>
      </div>
      <button
        onClick={downloadPDF}
        style={{
          marginTop: "20px",
          padding: "10px 20px",
          backgroundColor: "#10B981",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          fontSize: "1rem",
        }}
      >
        Download Receipt
      </button>
    </div>
  );
};

export default Receipt;
